import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const slice = createSlice(
  {
    name: 'order',
    initialState,
    reducers: {
      setAuth: (state, action) => {
        const value = action.payload;
        state = value;
        return state;
      }
    }
  }
)

export const { setAuth } = slice.actions;

export const getAuth = state => {
    return state.isAuth;
}

export default slice.reducer;
