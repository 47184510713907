import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {useState} from 'react';
import { Modal } from './components/modal';
import Statistic from './pages/statistic';
import Test1Long from './pages/test_1_long';
import Test2Long from './pages/test_2_long';
import Test1Short from './pages/test_1_short';
import Test2Short from './pages/test_2_short';
import Test3Long from './pages/test_3_long';
import Test3Short from './pages/test_3_short';
import Test4Long from './pages/test_4_long';
import Test4Short from './pages/test_4_short';
import Test5Long from './pages/test_5_long';
import Test5Short from './pages/test_5_short';

import "./styles/base.scss";
import "./styles/fonts.scss";

const Routes = () => {
    const [isPortrait, setPortrait] = useState(window.matchMedia("(orientation: portrait)").matches);

    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        if (window.matchMedia("(orientation: portrait)").matches) {
          setPortrait(true);
        } else {
          setPortrait(false);
        }
    });

    return (
      <>
        <Switch>
          <Route exact path={`${process.env.REACT_APP_URL}statistic`} component={Statistic}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_1`} component={Test1Long}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_2`} component={Test2Long}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_3`} component={Test1Short}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_4`} component={Test2Short}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_5`} component={Test3Long}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_6`} component={Test3Short}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_7`} component={Test4Long}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_8`} component={Test4Short}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_9`} component={Test5Long}/>
          <Route exact path={`${process.env.REACT_APP_URL}test_10`} component={Test5Short}/>
        </Switch>
        { isPortrait && <Modal isSmall alignCenter icon={`${process.env.REACT_APP_URL}assets/svg/rotation.svg`} title={process.env.REACT_APP_LANG === 'en' ? 'Please<br/>turn your phone' : 'Пожалуйста<br/>поверните телефон'}/>}
      </>
    )
  }

function App() {
  return (
    <Router>
        <Routes />
    </Router>
  );
}

export default App;
