import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/authSlice";

const reducer = {
  isAuth: authReducer,
}

export default configureStore({
  reducer,
});
