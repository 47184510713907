import React from 'react'
import Layout from '../layouts/base';
import { Modal, ModalProps } from '../components/modal/index';
import { Aside } from '../components/aside/index';
import PerfectScrollbar from 'perfect-scrollbar';
import axios from 'axios';
import '../styles/pages/test.scss';
import * as PIXI from 'pixi.js'

import data1 from '../data/test_2.json';
import data2 from '../data/survey_2.json';
import data3 from '../data/result_2.json';

const questionData = data1[process.env.REACT_APP_LANG || 'ru'];
const surveyData = data2[process.env.REACT_APP_LANG || 'ru'];
const resultData = data3[process.env.REACT_APP_LANG || 'ru'];

const videoFileUrls = [
    "./assets/video/test2/01-seating-down.mp4",
    "./assets/video/test2/02-waiting.mp4",
    "./assets/video/test2/04-waiting.mp4",
    "./assets/video/test2/08-filler.mp4",
    "./assets/video/test2/05-disagree.mp4",
    "./assets/video/test2/03-agree.mp4",
    "./assets/video/test2/08-remembers.mp4",
];

type TestState = {
    currentOption: {
        src: string,
        text: string,
        number: number,
        isLoop?: boolean
    },
    textResult: string,
    testResult : any[],
	modal: ModalProps,
    modalEmail: ModalProps,
	staging: any,
	currentStage: string,
    question: string|number,
    result: ModalProps,
    redirect: ModalProps,
    timer: number,
    currentCard: {
        src: string,
        alt: string
    },
    data: object,
    showModalEmail: boolean,
}

export default class Index extends React.Component<{}, TestState> {
    private testRef: React.RefObject<HTMLDivElement> | null;
    private scrollbar: any;
    private textures: PIXI.Texture[];
    private app: PIXI.Application | null = null;
    private timer: any = null;

    constructor(props) {
        super(props);
        this.testRef = React.createRef();
        this.state = {
            currentOption: {
                src: '',
                text: '',
                number: 0,
            },
            textResult: '',
            testResult: [],
            modal: {
                isMedium: true,
                title: process.env.REACT_APP_LANG === 'en' ? 'Hello, ladies and gentlemen!<br/>We invite you to take part in the interactive quest during which you will be able to carry out a full diagnosis of a patient of gastroenterological profile:' : 'Добрый день!<br/>Предлагаем принять участие в интерактивном квесте, в ходе которого Вы сможете провести полноценную диагностику пациента гастроэнтерологического профиля:',
                text: process.env.REACT_APP_LANG === 'en' ? '<ul><li>An initial consultation will allow you to collect the data concerning complaints, history of life and present illness of a patient, and afterwards you will be able to suggest a diagnosis and assign an empiric treatment.</li><li>The return visit will help you to correct the diagnosis and assign a treatment based on the results of the examination. Hope you will enjoy!</li></ul>' : '<ul><li>Первичный прием пациента позволит Вам получить данные о жалобах, анамнезе жизни и болезни пациента, после чего Вы сможете предположить диагноз и назначить эмпирическую терапию.</li><li>Повторный приём поможет скорректировать диагноз и назначить лечение на основе результатов обследования. Надеемся Вам будет интересно!</li></ul>',
                button: {
                    text: process.env.REACT_APP_LANG === 'en' ? 'Start' : 'Начать',
                    isArrow: true,
                    onClick: () => {this.setState((state) => {
                        return {currentStage: state.staging.START};
                        }, () => {
                            this.loadVideo(videoFileUrls[0]);
                        });
                    }
                }
            },
            result: {
                isLarge: true,
                navigation: true,
                buttons: [
                    {
                        text: process.env.REACT_APP_LANG === 'en' ? 'Download PDF' : 'Скачать PDF',
                        onClick: () => {
                            const content = this.testRef?.current?.querySelector<HTMLElement>('.modal__text');
                            var modalWrp = content?.cloneNode(true);
                            if (!modalWrp) return;
                            var elem = this.testRef?.current?.nextElementSibling;

                            const isIFrame = (input: Element | HTMLElement | null): input is HTMLIFrameElement =>
                                input !== null && input.tagName === 'IFRAME';

                            if (modalWrp && elem && isIFrame(elem)) {
                                var pri = elem.contentWindow;
                                if (!pri) return;
                                pri.document.open();
                                var divs = (modalWrp as HTMLElement).getElementsByTagName("div");
                                if (divs) {
                                    [].slice.call(divs).forEach((div: HTMLElement) => {
                                        div?.parentNode?.removeChild(div);
                                    })
                                }
                                pri.document.write((modalWrp as HTMLElement).innerHTML);
                                pri.document.close();
                                pri.focus();
                                pri.print();
                            }
                        },
                        isOrange: true
                    },
                    {
                        text: process.env.REACT_APP_LANG === 'en' ? 'Send by email' : 'Отправить на почту',
                        onClick: () => {
                            this.setState({showModalEmail: true});
                        },
                        disabled: true,
                        isOrange: true
                    },
                    {
                      text: process.env.REACT_APP_LANG === 'en' ? 'Complete' : 'Завершить прием',
                      onClick: () => {
                        window.location.reload();
                      },
                      isOrange: true
                    }
                ]
            },
            redirect: {
                title: process.env.REACT_APP_LANG === 'en' ? 'The results of the examination are ready' : 'Готовы результаты обследований',
                subtitle: process.env.REACT_APP_LANG === 'en' ? 'The patient will be back for the return visit soon' : 'Пациент скоро прибудет на повторный прием',
                button: {
                    text: process.env.REACT_APP_LANG === 'en' ? 'Look' : 'Смотреть',
                    isArrow: true,
                    onClick: () => {this.setState((state) => {
                        return {currentStage: state.staging.SURVEY};
                    })}
                }
            },
            staging: {
                PRE_START: "PRE_START",
                START: "START",
                REDIRECT: "REDIRECT",
                SURVEY: "SURVEY",
                RESULT: "RESULT",
            },
            currentStage: "PRE_START",
            currentCard: {
                src: '',
                alt: '',
            },
            question: 0,
            timer: 3000,
            data: {},
            showModalEmail: false,
            modalEmail: {
                btnClose: () => {this.setState({showModalEmail: false})},
                title: process.env.REACT_APP_LANG === 'en' ? 'Write your email' : 'Укажите адрес вашей почты',
                form: {
                    action: "#",
                    method: "GET",
                    fields: [
                        {
                            type: 'email',
                            name: 'email',
                            placeholder: process.env.REACT_APP_LANG === 'en' ? 'Your email' : 'Ваша почта'
                        },
                        {
                            type: 'button',
                            text: process.env.REACT_APP_LANG === 'en' ? 'To send' : 'Отправить'
                        }
                    ],
                    onSubmit: (e: any) => {
                        const formData: Record<string, unknown> = {};
                        e.preventDefault();
                        const errors:any = [];

                        const formElems = [].slice.call(e.target.elements).filter((elem: any) => elem.tagName !== 'BUTTON');
                        formElems.forEach((item: any) => {
                            if (item.validity.valid && item.value) {
                                item.parentElement.removeAttribute('data-error');
                                formData[item.name] = item.value;
                            } else {
                                item.parentElement.setAttribute('data-error', true);
                                errors.push(item.parentElement);
                            }
                        });

                        const content = this.testRef?.current?.querySelector<HTMLElement>('.modal__text');

                        const modalWrp = content?.cloneNode(true);
                        if (!modalWrp) return;

                        let divs = (modalWrp as HTMLElement)?.getElementsByTagName("div");
                        if (divs) {
                        [].slice.call(divs).forEach((div: HTMLElement) => {
                            div?.parentNode?.removeChild(div);
                        })
                        }

                        if (errors.length === 0) {
                            this.setState({showModalEmail: false});
                        }

                        const data = {
                            "testName": "test2",
                            "email": formData.email,
                            "description": (modalWrp as HTMLElement)?.innerHTML,
                        }

                        fetch('/testpdf/pdfscript.php', {
                            "method": "POST",
                            "headers": {
                                "Content-type": "application/json; charset=utf-8"
                        },
                        "body": JSON.stringify(data)
                        }).then(response => response.json())
                        .catch((error) =>{
                            console.log(error);
                        })

                    }
                }
            },
        }
        this.textures = [];
    }

    // текстуры в массив, контроль хода загрузки, оптимизация видосов
    loadVideo(src?, loop?) {
        if (this.app) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            const texture = this.textures.filter((item) => item.textureCacheIds[0] === src)[0];
            if (!texture) return;
            const resource = texture.baseTexture.resource as any;
            const videoSprite = new PIXI.Sprite(texture);
            videoSprite.width = this.app.screen.width;
            videoSprite.height = this.app.screen.height;

            while (this.app.stage.children.length > 2) {
                this.app.stage.removeChild(this.app.stage.children[0]);
            }
            this.app.stage.addChild(videoSprite);
            resource.source.play();
            resource.source.onended = () => {
                if (this.state.currentStage === this.state.staging.SURVEY || this.state.currentStage === this.state.staging.REDIRECT) {
                    return;
                }
                const srcPrev = resource.source.querySelector('source').src;
                this.timer = setTimeout(() => {
                    if (loop) {
                        resource.source.loop = true;
                        resource.source.play();
                        return;
                    } else if (srcPrev === src) {
                        resource.source.play();
                        return;
                    } else {
                        const num = this.randomInteger(1, 3);
                        this.loadVideo(videoFileUrls[num]);
                    }
                }, 2000);
            }
        }
	}

    createReaction(e, loop?) {
        if (!e) return;
        this.loadVideo(e.src, loop);

        this.setState({currentOption: e});
    }

    nextStep(val: string|number, e?: any, dataForm?: object) {
        if (val === 'reaction') {
            this.createReaction(e);
            return;
        }
        if (dataForm) {
            this.setState({data: Object.assign(this.state.data, dataForm)});
        }
        if (this.state.question === 9) {
            this.setState((state) => {
                return {currentStage: state.staging.REDIRECT, question: 10};
            });
        } else if (this.state.question === (questionData.length - 1)) {
            this.setResults();
            this.setState((state) => {
                return {currentStage: state.staging.RESULT};
            });
        } else {
            this.setState((state) => {
                return {question: Number(val) || ((val === 'prev') ? 1 : Number(state.question) + 1)};
            },
            () => {
                if (val === 10) { // add one more video
                    this.loadVideo(videoFileUrls[2], true);
                } else {
                    const num = this.randomInteger(1, 3);
                    this.setState({currentOption: {
                        src: videoFileUrls[num],
                        text: '',
                        number: this.state.currentOption.number,
                    }});
                }
            }
            );
        }
    }

    randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    surveyShow(val: string|number, e?: any, data?: object) {
        if (Number(val)) {
            this.setState((state) => {
                return {currentStage: state.staging.START};
            });
            this.nextStep(val, e, data);
            return;
        }
        const options = [].slice.call(document.querySelectorAll('.aside__option'));
        options.forEach((option: any) => {
            option.classList.remove('aside__option--opacity');
            if (option.dataset.id === e.currentTarget.dataset.id) {
                if (option !== e.currentTarget) {
                    option.click();
                }
                this.setState({currentCard: {
                    alt: surveyData.options.filter((opt) => opt.val === val)[0].text,
                    src: surveyData.options.filter((opt) => opt.val === val)[0].img
                }});

                this.scrollbar.element.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                return;
            }
            option.classList.add('aside__option--opacity');
        });
        if (!this.testRef?.current) return;
        const cards = [].slice.call(this.testRef.current.querySelectorAll('.test__survey-item'));
        if (cards.length) {
            cards.forEach((card: any) => {
                card.classList.remove('active');
                if (card.dataset.id === val) {
                    card.classList.add('active');
                }
            });
        }
    }

    setResults() {
        let newText = resultData.text;
        Object.keys(this.state.data).forEach((key) => {
            if (!this.state.data[key] || !resultData[key]) return;
            if (Array.isArray(this.state.data[key])) {
                let allText = '';
                if (key === 'test') {
                    if (this.state.data[key].includes('HPylori') && this.state.data[key].includes('ADGS')) {
                        allText += resultData[key]['ADGS&HPylori'];
                    } else if (this.state.data[key].includes('HPylori') || this.state.data[key].includes('ADGS')) {
                        this.state.data[key].forEach((elem, i) => {
                            if (!resultData[key][elem]) return;
                            allText += resultData[key][elem];
                        });
                    } else {
                        allText += resultData[key]['default'];
                    }
                } else {
                    if (key === 'fin-cure') {
                        const list = {};
                        this.state.data[key].forEach((elem, i) => {
                            if (!resultData[key][elem]) return;
                            if (typeof resultData[key][elem] !== 'object') {
                                allText += allText.length === 0 ? resultData[key][elem] : ` ${process.env.REACT_APP_LANG === 'en' ? 'Moreover, ' : 'Также'} ${resultData[key][elem]}`;
                            } else {
                                if (list[resultData[key][elem].group]) {
                                    list[resultData[key][elem].group].push(resultData[key][elem].text);
                                } else {
                                    list[resultData[key][elem].group] = [resultData[key][elem].text];
                                }
                            }
                        });
                        Object.keys(list).forEach((item) => {
                            allText += allText.length === 0 ? `${process.env.REACT_APP_LANG === 'en' ? 'You have suggested to the patient' : 'Вы предложили пациенту'} ${list[item].join(', ')}. ${resultData[`${key.split('cure')[0]}groups`][item]}` : `${process.env.REACT_APP_LANG === 'en' ? 'You have also suggested to the patient' : 'Также Вы предложили пациенту'} ${list[item].join(', ')}. ${resultData[`${key.split('cure')[0]}groups`][item]}`;
                        });
                    }  else {
                        this.state.data[key].forEach((elem, i) => {
                            allText += i === 0 ? resultData[key][elem] : resultData[key][elem];
                        });
                    }
                }


                newText = newText.replace(`--${key}--`, allText);
            } else {
                newText = newText.replace(`--${key}--`, resultData[key][this.state.data[key]])
            }
        });


        this.setState({textResult: newText});

        this.sendResults();
    }

    sendResults() {
        const formData = new FormData();
        Object.keys(this.state.data).forEach((elem) => {
            if (elem === 'start' || elem === 'survey') return;
            formData.append(elem, Array.isArray(this.state.data[elem]) ? this.uniqResults(this.state.data[elem]) : this.state.data[elem]);
        });
        axios.post('/local/ajax/test2.php', formData).then((response) => {
            console.log(response);
        }).catch(e => {
            console.error(e);
        });
    }

    uniqResults(list) {
        return list.filter(function(item, pos) {
            return list.indexOf(item) === pos;
        });
    }

    componentDidUpdate(_, state) {
        if (this.state.currentStage === this.state.staging.SURVEY && state !== this.state) {
            const survey = this.testRef?.current?.querySelector('.test__survey-list');
            if (!survey) return;

            this.scrollbar = new PerfectScrollbar(survey, {
                maxScrollbarLength: 80
            });

            if (!this.state.testResult.length) {
                const tests: any[] = [];
                this.state.data[surveyData.id].forEach((item: string) => {
                    if (item === 'OAK') {
                        ['OAK', 'BAK', 'AK'].forEach((elem: string) => {
                            const test = surveyData.options.find((option) => option.id === elem);
                            if (test) {
                                tests.push(test);
                            }
                        });
                    } else {
                        const test = surveyData.options.find((option) => option.id === item);
                        if (test) {
                            tests.push(test);
                        }
                    }
                });
                this.setState({testResult: tests});
            }
        }
    }

    componentWillUnmount() {
        if (this.scrollbar) {
            this.scrollbar.destroy();
            this.scrollbar = null;
        }
    }

    setCanvasWH(app) {
        if (!app) return;
        if (window.innerWidth * 0.56 < window.innerHeight) {
            app.view.width = window.innerHeight / 0.56;
            app.view.height = window.innerHeight;
            app.screen.width = window.innerHeight / 0.56;
            app.screen.height = window.innerHeight;
        } else {
            app.view.width = window.innerWidth;
            app.view.height = window.innerWidth * 0.56;
            app.screen.width = window.innerWidth;
            app.screen.height = window.innerWidth * 0.56;
        }
    }

    componentDidMount() {
        const canvas = document.querySelector('#canvas');
        this.app = new PIXI.Application({transparent: true});

        videoFileUrls.forEach((src) => {
            this.textures.push(PIXI.Texture.from(src))
        });

        if (canvas) {
            canvas.appendChild(this.app.view);
        }
        this.setCanvasWH(this.app);
        this.app.resize();

        window.addEventListener('resize', () => {
            if (!this.app) return;
            this.setCanvasWH(this.app);
            this.app.resize();
        });
    }

	render() {
		return (
            <Layout>
                <div className='test test-man' ref={this.testRef}>
                    { this.state.currentStage !== this.state.staging.RESULT &&
                        <>
                            <div id="canvas" style={this.state.currentStage === this.state.staging.SURVEY || this.state.currentStage === this.state.staging.REDIRECT ? {visibility: 'hidden'}: {}}></div>
                        </>
                    }
                    { (this.state.currentStage === this.state.staging.START && (this.state.currentOption.text || questionData[this.state.question]?.reaction)) &&
                        <div className={'test__reaction'} data-point={this.state.currentOption.number}>{this.state.currentOption.text || questionData[this.state.question]?.reaction}</div>
                    }
                    { this.state.currentStage === this.state.staging.PRE_START && <Modal {...this.state.modal} isHeading={true}/> }
                    { this.state.currentStage === this.state.staging.START &&
                        <Aside {...questionData[this.state.question]} questions={questionData.length} question={this.state.question} onClickEvt={(val, e, data) => this.nextStep(val, e, data)}/>
                    }
                    { this.state.currentStage === this.state.staging.REDIRECT && <Modal {...this.state.redirect}/> }
                    { this.state.currentStage === this.state.staging.SURVEY &&
                        <div className='test__survey'>
                            { !this.state.currentCard.src && <div className='test__survey-list'>
                                { this.state.testResult.map((option) => (
                                    <div className='test__survey-item' key={option.val} data-id={option.val} onClick={(e) => this.surveyShow(option.val, e)}>
                                        <span className='test__survey-title'>{option.text}</span>
                                        <img src={`${process.env.REACT_APP_URL}assets/images/survey.png`} alt={option.text}/>
                                    </div>
                                ))}
                            </div> }
                            { this.state.currentCard.src && <div className='test__survey-list'><div className='test__survey-img'>
                                <img src={this.state.currentCard.src} alt={this.state.currentCard.alt}/>
                            </div></div>}
                            <Aside {...surveyData} options={this.state.testResult} questions={questionData.length} className={'test__aside'} id={'survey'} question={'survey'} onClickEvt={(val, e, data) => this.surveyShow(val, e, data)}/>
                        </div>
                    }
                    { this.state.currentStage === this.state.staging.RESULT &&
                        <Modal {...this.state.result} text={this.state.textResult}>
                            { this.state.showModalEmail && <Modal {...this.state.modalEmail}></Modal>}
                        </Modal>
                    }
                </div>
                <iframe id="ifmcontentstoprint" title="print test" style={{height: "0px", width: "0px", top: 0, position: "absolute", visibility: "hidden"}}></iframe>
                <span className='id-test'>{process.env.REACT_APP_LANG === 'en' ? '2289842 (v1.0)' : 'RUS2289842 (v1.0)'}</span>
            </Layout>
		);
	}
}
