import React from "react";
import { Button } from '../button';
import { ReactSVG } from "react-svg";
import Parser from "html-react-parser";
import PerfectScrollbar from 'perfect-scrollbar';

import './style.scss';
import '../../styles/base.scss';
import cn from 'classnames';

const TEXTS_TITLE = {
  nextSection: 'Отлично, выберите следующий раздел',
  notCompleted: 'Кажется какой-то раздел пройден не до конца',
  halfCompleted: 'Половина пути пройдена, полный вперед!',
  examination: 'Вам доступен раздел обследование'
} as const;

export type AsideEvents = {
  onClickEvt?: (val: string | number, e: any, data?: object, name?: string) => void,
};

type AsideState = {
  isRemoveDisabled: boolean,
  isBtnDisabled: boolean,
  isLock?: boolean,
  formData: object,
  timer: number,
  checkboxes: object,
  title: string | null
  total: number | null
  totalOption: number
  isScrollFinCure: boolean
  totalVariants: number
  isOpenTest: boolean
  isHiddenTestOptions: boolean
};

export type AsideProps = {
  id: string | number;
  className?: string;
  question: string | number;
  total?: number;
  questions: number;
  timing?: string;
  info?: string;
  header?: any;
  title?: string;
  subtitle?: string;
  text?: string;
  options?: any[];
  history?: {
    name: string
    img: string
    items: {
      title: string
      description: string
    }[]
  }
  noOptionsBg?: boolean;
  footer?: any;
  isNewTest?: boolean;
  isOpenTest?: boolean;
  btnNext?: any;
  totalOptions?: number
} & AsideEvents

export class Aside extends React.Component<AsideProps, AsideState> {
  private optionsRef: React.RefObject<HTMLDivElement> | null;
  private historyRef: React.RefObject<HTMLDivElement> | null;
  private asideRef: React.RefObject<HTMLDivElement> | null;
  private ps: any;
  private tests: any[];
  private checkedOpts: any[];

  constructor(props: AsideProps) {
    super(props);
    this.tests = [];
    this.checkedOpts = [];
    this.state = {
      isRemoveDisabled: false,
      isBtnDisabled: false,
      isLock: true,
      isOpenTest: false,
      isHiddenTestOptions: false,
      formData: {},
      timer: 3000,
      checkboxes: {},
      title: null,
      total: null,
      totalOption: 0,
      isScrollFinCure: false,
      totalVariants: 0
    }

    this.asideRef = React.createRef();
    this.optionsRef = React.createRef();
    this.historyRef = React.createRef();
  }

  onClick(val: string | number, e?: any, data?: object, total?: number, name?: string) {
    if (val === 'SURVEY' && this.props.onClickEvt) {
      this.props.onClickEvt(val, e, data);
      return;
    }

    if (name === 'test-sitDown') {
      this.setState({isOpenTest: true})
    }

    if (name === 'test-up') {
      const durationVideo = 1600;
      this.setState({isHiddenTestOptions: true})

      setTimeout(() => {
        this.setState({isOpenTest: false, isHiddenTestOptions: false})
      }, durationVideo)

    }

    if (this.state.formData[this.props.id]) {
      if ((this.state.formData[this.props.id].length === this.state.totalOption)) {
        if (Object.keys(this.state.formData).length - 1 === (this.state.totalVariants / 2)) {
          this.setState({title: TEXTS_TITLE.halfCompleted});
        } else {
          this.setState({title: TEXTS_TITLE.nextSection})
        }
      } else {
        this.setState({title: TEXTS_TITLE.notCompleted})
      }
    }

    if (Object.keys(this.state.formData).length) {
      let totalSelectValue = 0;

      Object.keys(this.state.formData).forEach((key) => {
        if (key !== 'start') {
          totalSelectValue += this.state.formData[key]?.length;
        }
      })

      const total = this.state.total ?? 1;

      if (totalSelectValue >= total) {
        this.setState({isLock: false, title: TEXTS_TITLE.examination});
      }
    }

    if (this.props.onClickEvt) {
      if (name) {
        this.props.onClickEvt(val, e, data, name);
      } else {
        this.props.onClickEvt(val, e, data);
      }

      if (total) {
        this.setState({totalOption: total});
      }

      if (this.ps) {
        this.ps.element.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    }

    if ((val === 'prev' || typeof val === 'number') && this.ps) {
      this.ps.destroy();
      this.ps = null;
    }

    if (!val || val === 'prev' || typeof val === 'number') return;
    if (!this.state.formData[this.props.id]) {
      this.tests.push(val);
      this.setState((state) => {
        state.formData[this.props.id] = [val];
      });
    } else {
      if (this.state.formData[this.props.id].includes(val)) return;
      this.setState((state) => {
        const currValue = state.formData[this.props.id];

        return {
          formData: {
            ...state.formData,
            [this.props.id]: currValue.concat([val])
          }
        }
      });
      this.tests.push(val);
    }
  }

  setCheckbox(e) {
    const id = e.target.parentElement.parentElement.dataset.id;

    this.state.checkboxes[id] ?
      this.setState((state) => {
        state.checkboxes[id][e.target.name] = e.target.checked;
      })
      : this.setState((state) => {
        state.checkboxes[id] = {[e.target.name]: e.target.checked};
      });
    this.setState((state) => {
      state.formData[id] = Object.keys(state.checkboxes[id]).filter(elem => state.checkboxes[id][elem] === true);
    });
    this.checkedOpts = [].slice.call(e.target.parentElement.parentElement.querySelectorAll('input')).filter((item: any) => item.checked);
    if (this.checkedOpts.length && (Object.keys(this.state.formData).length >= 4 || this.props.question > 5)) {
      this.setState({isBtnDisabled: false});
    } else {
      this.setState({isBtnDisabled: true});
    }
  }

  setRadio(e) {
    this.setState((state) => {
      state.formData[e.target.parentElement.parentElement.dataset.id] = e.target.value;
    });
    const checkedOpts = [].slice.call(e.target.parentElement.parentElement.querySelectorAll('input')).filter((item: any) => item.checked);
    if (checkedOpts.length) {
      this.setState({isBtnDisabled: false});
      if (this.props.onClickEvt && this.props.options) {
        const reacts = this.props.options.filter(option => option.value === e.target.value)[0];
        if (reacts.reactions) {
          this.props.onClickEvt('reaction', Object.assign(reacts.reactions, {timer: this.state.timer, number: 0}));
        }
      }
    } else {
      this.setState({isBtnDisabled: true});
    }
  }

  checkOption(e) {
    const activeClass = this.props.isNewTest ? 'aside__option--new-mark' : 'aside__option--mark';

    const options = [].slice.call(e.target.parentElement.children);
    options.forEach((option: any, i) => {
      if (!option.classList.contains('aside__option')) return;
      option.classList.remove(activeClass);

      if ((option === e.target) && this.props.options) {
        if (this.props.options[option.dataset.id].reactions) {
          option.classList.add(activeClass);

          if (this.props.onClickEvt) {
            this.props.onClickEvt('reaction', Object.assign(this.props.options[option.dataset.id].reactions, {
              timer: 0,
              number: Number(option.dataset.id)
            }));
          }
        }
      }
    });

    const idArr = this.state.formData[e.target.parentElement.dataset.id];
    if (idArr) {
      if (idArr.includes(e.target.dataset.id)) return;
      this.setState((state) => {
        const currValue = state.formData[e.target.parentElement.dataset.id];
        return {
          formData: {
            ...state.formData,
            [e.target.parentElement.dataset.id]: currValue.concat([e.target.dataset.id])
          }
        }
      });
    } else {
      this.setState((state) => {
        state.formData[e.target.parentElement.dataset.id] = [e.target.dataset.id];
      });
    }

    if (!this.state.isRemoveDisabled) {
      options.forEach((elem: any) => {
        elem.removeAttribute('data-disabled');
      });
      this.setState({isRemoveDisabled: true});
    }

    if (this.state.isBtnDisabled && ((this.checkedOpts.length && Object.keys(this.state.formData).length >= 4) || this.props.question > 5)) {
      this.setState({isBtnDisabled: false});
    }
  }

  uniqResults(list) {
    return list.filter(function (item, pos) {
      return list.indexOf(item) === pos;
    });
  }

  componentDidMount() {
    if (this.props.id === 'start') {
      const totalVariants = this.props.options?.length ?? 4;
      this.setState({totalVariants: totalVariants});
    }

    if (this.props.question === 'survey' && !this.state.isBtnDisabled) {
      this.setState({isBtnDisabled: true});
    }

    if (this.props.question === 8 && !this.state.isBtnDisabled) {
      this.setState({isBtnDisabled: true});
    }
  }

  componentDidUpdate(props, state) {
    if (props.options && props.id === 'anamnesis_injury') {
      props.options.forEach((el, i) => {
        if (!Boolean(el.reactions) && !el.value) {
          this.setState((state) => {
            state.formData[props.id] = [i];
          });
        }
      })
    }

    if (this.props.id === 'start') {
      if (!this.state.total) {
        this.setState({total: this.props.totalOptions ?? 1})
      }
    }

    if ((this.props.footer && this.props.question !== props.question && (Object.keys(this.state.formData).length !== 5))) {
      this.setState({isBtnDisabled: true});
    }

    if (this.props.question === 5 && this.state.checkboxes[this.props.id] && Object.keys(this.state.checkboxes[this.props.id]).length) {
      [].slice.call(this.optionsRef?.current?.children).forEach((elem: any) => {
        if (!elem.classList.contains('aside__option')) return;
        const inputEl = elem.querySelector('input');
        if (this.state.checkboxes[this.props.id][inputEl.name]) {
          inputEl.checked = this.state.checkboxes[this.props.id][inputEl.name] || false;
        }
      });
    }

    if (this.props.question === 6) {
      if (!this.state.formData[this.props.id]) {
        if (this.props.question === props.question) return;
        this.setState({isBtnDisabled: true});
      }
    }

    if (this.props.question === 'survey' && this.state.formData[this.props.id] && this.tests.length === this.props.options?.length && this.state.isBtnDisabled) {
      this.setState({isBtnDisabled: false});
    }

    const initPsScroll = (elemScroll: HTMLElement) => {
      this.ps = new PerfectScrollbar(elemScroll, {
        maxScrollbarLength: 80,
        suppressScrollX: true
      });
    }

    if (!this.ps && this.historyRef?.current) {
      initPsScroll(this.historyRef.current);
    }

    if (!this.ps && this.optionsRef?.current && (this.optionsRef?.current?.offsetHeight <= this.optionsRef?.current?.scrollHeight)) {
      initPsScroll(this.optionsRef.current);
    }

    const question = this.props.id === "fin-diagnosis" ? Number(this.props.question) - 1 : this.props.question;
    const isFin = this.props.id === "fin-diagnosis" ? !this.state.isScrollFinCure : true;

    if (this.props.history && !this.ps) {
      this.setWrapperHeight();
    }

    if ((question !== props.question) && isFin) {
      this.setWrapperHeight();
    }
  }

  setWrapperHeight() {
    const aside = this.asideRef?.current;
    const asideContent = this.asideRef?.current?.querySelector('.aside__content');

    if (!asideContent) return;

    const asideElems = [].slice.call(asideContent?.children);
    if (asideElems && asideElems.length) {
      const asideWrp: any = asideElems.find((elem: HTMLElement) => elem.classList.contains('aside__wrapper') || elem.classList.contains('aside__history-wrapper'));
      const asideFooter: any = asideElems.find((elem: HTMLElement) => elem.classList.contains('aside__footer'));
      const asideBtnNext: any = asideElems.find((elem: HTMLElement) => elem.classList.contains('aside__btn-next'));

      if (aside && asideWrp) {
        asideWrp.style.height = 'auto';
        const asideHeight = aside.getBoundingClientRect().height;
        const heightEl = asideFooter ? asideFooter?.offsetHeight : asideBtnNext?.offsetHeight + 61
        let wrpHeight = asideHeight - asideWrp.offsetTop - ((asideFooter && window.innerHeight > 375) || asideBtnNext ? heightEl : 0);

        const offsetHeightHistory = this.props.history ? 100 : 40;

        if (asideWrp.scrollHeight >= wrpHeight) {
          asideWrp.style.height = wrpHeight - (window.innerHeight > 400 ? offsetHeightHistory : 10) + 'px';

          if (this.props.id === "fin-diagnosis") {
            this.setState({isScrollFinCure: true})
          } else {
            if (this.props.history) return;

            this.setState({isScrollFinCure: false})
          }
        }
      }
    }
  }

  setTooltip(e) {
    const button = e.currentTarget.querySelector('.button');
    if (button && button['disabled']) {
      const target = e.currentTarget;
      target.classList.add('aside__footer--tooltip');
      document.body.style.pointerEvents = 'none';
      setTimeout(() => {
        target.classList.remove('aside__footer--tooltip');
        document.body.style.pointerEvents = 'all';
      }, 1000);
    }
  }

  getIconOptionsView(id: string, total: number): string {
    if ((this.state.formData[id]?.length === total)) {
      return 'arrowView';
    } else {
      return 'eye';
    }
  }

  findOptionDirty(type: string | number, value: number): boolean {
    if (this.props.id === 'start') return false;

    return Boolean(this.state.formData[type]?.find((el) => Number(el) === value));
  }

  setPointerEvents(option: any): 'auto' | 'none' {
    if (option.val) {
      return 'auto';
    } else {
      if (Boolean(option.reactions)) {
        return 'auto';
      } else {
        return 'none';
      }
    }
  }

  componentWillUnmount() {
    if (this.ps) {
      this.ps.destroy();
      this.ps = null;
    }
  }


  render() {
    return (
      <div
        className={cn(`aside ${this.props.className ? this.props.className : ''}`, {
          'aside--test': this.state.isOpenTest && this.props.isNewTest,
          'aside--hidden-options': this.state.isHiddenTestOptions
        })}
        ref={this.asideRef}
      >
        {((this.props.id !== 'test') || !this.props.isNewTest) && (
          <div className={'aside__content'}>
            {this.props.info && <div className={'aside__info'} onClick={(e) =>
              (window.matchMedia("(orientation: landscape)").matches && window.innerHeight < 766 && window.innerWidth < 811) ? e.currentTarget.classList.remove('aside__info--hide') : {}
            }>
              <ReactSVG className="aside__info-icon" src={`${process.env.REACT_APP_URL}assets/svg/info.svg`}/>
              {(window.matchMedia("(orientation: landscape)").matches && window.innerHeight < 766 && window.innerWidth < 811) ?
                <button className="aside__info-close" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.currentTarget.parentElement?.classList.add('aside__info--hide')
                }}><ReactSVG src={`${process.env.REACT_APP_URL}assets/svg/close.svg`}/></button> : ''}
              <span>{this.props.info}</span>
            </div>}
            {this.props.timing && <div className={'aside__timing'}>
              <ReactSVG className="aside__timing-icon" src={`${process.env.REACT_APP_URL}assets/svg/timing.svg`}/>
              {this.props.timing}
            </div>}
            {this.props.header &&
                <div
                    className={cn(
                      `aside__header ${((this.props.question < 5 && this.uniqResults(this.state.formData[this.props.id] || []).length < (this.props.options?.length || 0) || this.props.question === 5 && (!this.state.formData[this.props.id] || this.state.formData[this.props.id].length === 0)) || this.props.isNewTest) ? 'aside__header--no-cl' : ''}`,
                      {'aside__header--large': (this.props.isNewTest && window.innerWidth >= 1280)}
                    )}
                    style={this.props.header ? {} : window.innerWidth < 811 ? {'display': 'none'} : {'visibility': 'hidden'}}
                    onClick={() => this.onClick('prev')}>{this.props.header?.text}</div>
            }
            {this.props.title &&
                <div
                    className={cn('aside__title', {
                      'aside__title--big': this.props.isNewTest,
                      'aside__title--history': this.props.history
                    })}
                >
                  {this.state.title ?? this.props.title}
                </div>}
            {this.props.subtitle && <div
                className={cn('aside__subtitle', {'aside__subtitle--big': this.props.isNewTest})}>{Parser(this.props.subtitle)}</div>}
            {this.props.text && <div
                className={cn('aside__text', {'aside__text--big': this.props.isNewTest})}>{Parser(this.props.text)}</div>}
            {this.props.options &&
                <div
                    className={
                      cn(
                        `aside__wrapper ${this.props.noOptionsBg ? 'aside__wrapper--noBg' : ''}`, {
                          'aside__wrapper--new': this.props.isNewTest && (this.props.id !== 'start'),
                          'aside__wrapper--no-bg': this.props.isNewTest || this.props.btnNext,
                        }
                      )
                    }
                >
                  <div
                      className={'aside__options'}
                      data-id={this.props.id}
                      data-question={this.props.question}
                      ref={this.optionsRef}
                  >
                    {this.props.options.map((option, i) => (
                        option.type === 'checkbox'
                          ? <label
                            className={'aside__option'}
                            key={option.text}>
                            <input type={option.type} name={option.name} onChange={(e) => this.setCheckbox(e)}/>
                            <span>{option.text}</span>
                          </label>
                          : option.type === 'radio'
                            ? <label
                              className={'aside__option'}
                              key={option.text}>
                              <input type={option.type} name={option.name} value={option.value}
                                     onChange={(e) => this.setRadio(e)}/>
                              <span>{option.text}</span>
                            </label>
                            : <div
                              data-id={option.id || i}
                              style={option.isLock ? undefined : {pointerEvents: this.setPointerEvents(option)}}
                              className={cn('aside__option', {
                                'aside__option--white': this.props.isNewTest,
                                'aside__option--lock': this.state.isLock && option.isLock,
                                'aside__option--viewed': (this.state.formData[option.id]?.length === option?.total) && option.id,
                              })}
                              key={option.text}
                              data-disabled={this.props.question === (this.props.isNewTest ? 1 : 2) && Number(i) > 0 && !this.state.isRemoveDisabled}
                              data-dirty={this.props.isNewTest && this.findOptionDirty(this.props.id, i) && Boolean(option.reactions)}
                              onClick={(e) => option.val ? this.onClick(option.val, e, undefined, option.total, option?.name) : this.checkOption(e)}>
                              <ReactSVG className={'aside__option-arrowLeft'}
                                        src={`${process.env.REACT_APP_URL}assets/svg/arrowOption.svg`}/>
                              {option.text}
                              {option.isLock && <ReactSVG
                                  className={'aside__icon-lock'}
                                  src={`${process.env.REACT_APP_URL}assets/svg/${this.state.isLock ? 'lock' : 'unlock'}.svg`}
                              />}
                              {!option.isLock && this.state.formData[option?.id] && <ReactSVG
                                  src={`${process.env.REACT_APP_URL}assets/svg/${option?.total && option.id ? this.getIconOptionsView(option.id, option.total) : ''}.svg`}/>}
                              {this.props.isNewTest && this.findOptionDirty(this.props.id, i) && option.reactions &&
                                  <ReactSVG className={'aside__option-arrowView'}
                                            src={`${process.env.REACT_APP_URL}assets/svg/arrowView.svg`}/>}
                            </div>
                      )
                    )}
                  </div>
                </div>}
            {this.props.history && (
              <div className={'aside__history-wrapper'} ref={this.historyRef}>
                <div className="aside__history">
                  {this.props.history.name && this.props.history.img && (
                    <div className='aside__history-heading'>
                      <img className="aside__history-image"
                           src={`${process.env.REACT_APP_URL}${this.props.history.img}`} alt="user"/>
                      <div className="aside__history-name">{this.props.history.name}</div>
                    </div>
                  )}
                  {Array.isArray(this.props.history.items) && this.props.history.items.length > 0 && (
                    this.props.history.items.map((item, i) => (
                      <div className='aside__history-item' key={i}>
                        {item.title && <div className='aside__item-title'>{item.title}</div>}
                        {item.description && <div className='aside__item-description'>{item.description}</div>}
                      </div>
                    ))
                  )}
                </div>
                {this.props.history && (
                  <div className="aside__btn-conteiner--history">
                    <Button
                      text={'Отлично! Продолжаем'}
                      isArrow
                      className={cn('aside__btn-next')}
                      onClick={(e) => this.onClick('SURVEY', e, this.state.formData)}
                    />
                  </div>
                )}
              </div>
            )}
            {this.props.btnNext && (
              <div className="aside__btn-conteiner">
                <Button
                  {...this.props.btnNext}
                  disabled={this.props.btnNext.isHidden ? !Boolean(this.state.formData[this.props.id]?.length) : false}
                  className={cn('aside__btn-next', {
                    // 'aside__btn-next--hidden': this.props.btnNext.isHidden ? !Boolean(this.state.formData[this.props.id]?.length) : false,
                  })}
                  onClick={(e) => this.onClick(this.props.btnNext.value, e, this.state.formData)}
                />
              </div>
            )}
            {this.props.isNewTest && this.props.total && (
              <div className="aside__btn-conteiner">
                <Button
                  isArrow
                  text={this.props.id === 'anamnesis_life' ? 'Дальше' : 'Отлично! Продолжаем'}
                  className={cn('aside__btn-prev', {
                    'aside__btn-prev--visible': this.state.formData[this.props.id]?.length === Number(this.props.total) || this.props.options?.find((el) => !el.val && !Boolean(el.reactions))
                  })}
                  onClick={() => this.onClick('prev')}
                />
              </div>
            )}
            {this.props.footer && <div className={'aside__footer'}
                                       onClick={(e) => (window.matchMedia("(orientation: landscape)").matches && window.innerHeight < 766) ? this.setTooltip(e) : {}}>
              {this.props.footer.title && <p className={'aside__footer-title'}>{this.props.footer.title}</p>}
              {this.props.footer.button && <Button {...this.props.footer.button}
                                                   question={this.props.question > 1 && this.props.question < 5 ? Object.keys(this.state.formData).length * 100 / 5 : 100}
                                                   disabled={this.state.isBtnDisabled}
                                                   onClick={(e) => this.onClick(this.props.footer.button.val, e, this.state.formData)}/>}
            </div>}
          </div>
        )}
        {this.props.isOpenTest && (this.props.id === 'test') && (
          <div className={'aside__tests-content'}>
            <div
              className={'aside__header aside__header--large aside__header--no-cl aside__header--test-content'}
              style={this.props.header ? {} : window.innerWidth < 811 ? {'display': 'none'} : {}}
              onClick={() => this.onClick('prev', null, undefined, undefined, 'test-up')}
            >
              Обследование
            </div>
            {this.props.info && <div className={'aside__info aside__info--test-content'} onClick={(e) =>
              (window.matchMedia("(orientation: landscape)").matches && window.innerHeight < 766 && window.innerWidth < 811) ? e.currentTarget.classList.remove('aside__info--hide') : {}
            }>
              <ReactSVG className="aside__info-icon" src={`${process.env.REACT_APP_URL}assets/svg/info.svg`}/>
              {(window.matchMedia("(orientation: landscape)").matches && window.innerHeight < 766 && window.innerWidth < 811) ?
                <button className="aside__info-close" onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  e.currentTarget.parentElement?.classList.add('aside__info--hide')
                }}><ReactSVG src={`${process.env.REACT_APP_URL}assets/svg/close.svg`}/></button> : ''}
              <span>{Parser(this.props.info)}</span>
            </div>}
            <div className="aside__card-wrapper" data-id={this.props.id}>
              {this.props.options?.map((option) => (
                <label
                  className={'aside__card'}
                  key={option.text}>
                  <input type={option.type} name={option.name} onChange={(e) => this.setCheckbox(e)}/>
                  <div className={'aside__card-value'}></div>
                  <span>{option.text}</span>
                </label>
              ))}
            </div>
            <Button
              isArrow
              className={cn('aside__btn-test', {'aside__btn-test--hidden': !this.state.formData[this.props.id]?.length})}
              text={'Диагноз и лечение'}
              onClick={(e) => this.onClick(5, e, this.state.formData, undefined, 'test-up')}
            />
          </div>
        )}
      </div>
    );
  }
}
