import React from "react";
import './style.scss';

export type InputEvents = {
    onChange?: (state: any) => void,
};

export type InputProps = {
    name?: string,
    placeholder?: string,
	type?: string;
    failText?: string;
} & InputEvents & InputPropsCallback

type InputState = {
	focus: boolean,
	error: boolean,
	success: boolean,
}

interface InputPropsCallback {
    changeState?: (state: any) => void,
}

export class Input extends React.Component<InputProps, InputState>{
	constructor(props: InputProps) {
		super(props);
		this.state = {
            focus: false,
			error: false,
			success: false,
        }
	}

    onChange(value: string) {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

	handleError() {
        this.setState((state) => {
            return {
                error: !state.error,
            };
        });
    }

    render() {
        return (
		 <div className={'input'}>
			<input
				className={'input__item'}
				type={this.props.type}
				name={this.props.name}
                placeholder={this.props.placeholder}
				onChange={(e: any) => {this.onChange(e.target.value)}}/>
		</div>
        );
    }
}
