import React, { useEffect, useState } from 'react'
import Layout from '../layouts/base';
import { Button } from '../components/button';
import { Card } from '../components/card';
import { Modal, ModalProps } from '../components/modal';
import { getAuth, setAuth } from '../features/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';

import '../styles/pages/statistic.scss';

import data1 from '../data/statistic.json';

const statisticsData = {'items': []};
// const statisticsData = {'items': data1.ru.items};


const Index = () => {
    const dispatch = useDispatch();
    const baseStateAuth = useSelector(getAuth);
    const [isAuth, setBaseAuth] = useState(baseStateAuth);
    const [showModalEmail, setModalEmail] = useState(false);
    const statisticRef: React.RefObject<HTMLDivElement> = React.createRef();
    const modalEmail: ModalProps = {
        btnClose: () => {setModalEmail(false)},
        title: 'Укажите адрес вашей почты',
        form: {
            action: "#",
            method: "GET",
            fields: [
                {
                    type: 'email',
                    name: 'email',
                    placeholder: 'Ваша почта'
                },
                {
                    type: 'button',
                    text: 'Отправить'
                }
            ],
            onSubmit: (e: any) => {
                const formData = {};
                e.preventDefault();
                const errors:any = [];
                const formElems = [].slice.call(e.target.elements).filter((elem: any) => elem.tagName !== 'BUTTON');
                formElems.forEach((item: any) => {
                    if (item.validity.valid && item.value) {
                        item.parentElement.removeAttribute('data-error');
                        formData[item.name] = item.value;
                    } else {
                        item.parentElement.setAttribute('data-error', true);
                        errors.push(item.parentElement);
                    }
                });
                if (errors.length === 0) {
                    setModalEmail(false);
                }
            }
        }
    };
    const modal =  {
        title: process.env.REACT_APP_LANG === 'en' ? 'Welcome! ' : 'Добро пожаловать! ',
        subtitle: process.env.REACT_APP_LANG === 'en' ? 'You must be logged in to continue.' : 'Для того чтобы продолжить вам нужно войти.',
        form: {
            action: "#",
            method: "POST",
            fields: [
                {
                    type: 'text',
                    name: 'login',
                    placeholder: process.env.REACT_APP_LANG === 'en' ? 'Login' : 'Логин'
                },
                {
                    type: 'password',
                    name: 'password',
                    placeholder: process.env.REACT_APP_LANG === 'en' ? 'Password' : 'Пароль'
                },
                {
                    type: 'button',
                    text: process.env.REACT_APP_LANG === 'en' ? 'Log in' : 'Войти',
                }
            ],
            onSubmit: (e: any) => {
                const formData = new FormData();
                e.preventDefault();

                const errors:any = [];

                const formElems = [].slice.call(e.target.elements).filter((elem: any) => elem.tagName !== 'BUTTON');
                formElems.forEach((item: any) => {
                    if (item.validity.valid && item.value) {
                        item.parentElement.removeAttribute('data-error');
                        formData.append(item.name, item.value);
                    } else {
                        item.parentElement.setAttribute('data-error', true);
                        errors.push(item.parentElement);
                    }
                });
                if (errors.length === 0) {
                    axios.post('/local/ajax/auth.php', formData).then((response) => {
                        if (response.data.status !== 'false') {
                            setBaseAuth(true);
                            dispatch(setAuth(true));
                        } else {
                            const formElems = [].slice.call(e.target.elements).filter((elem: any) => elem.tagName !== 'BUTTON');
                            formElems.forEach((item: any) => {
                                item.parentElement.setAttribute('data-error', true);
                                errors.push(item.parentElement);
                            });
                        }
                    }).catch(e => {
                        console.error(e);
                        setBaseAuth(true);
                        dispatch(setAuth(true));
                    });
                }
            }
        }
    };
    const statistic =  {
        title: process.env.REACT_APP_LANG === 'en' ? 'Statistics' : 'Статистика',
        buttons: [
            {
                text: process.env.REACT_APP_LANG === 'en' ? 'Download PDF' : 'Скачать PDF',
                onClick: () => { // либо с апи
                    const content = statisticRef?.current;
                    var modalWrp = content?.cloneNode(true);
                    if (!modalWrp) return;
                    var elem = statisticRef?.current?.nextElementSibling;

                    const isIFrame = (input: Element | HTMLElement | null): input is HTMLIFrameElement =>
                        input !== null && input.tagName === 'IFRAME';

                    if (modalWrp && elem && isIFrame(elem)) {
                        var pri = elem.contentWindow;
                        if (!pri) return;
                        pri.document.open();
                        var buttons = (modalWrp as HTMLElement).querySelector(".statistic__buttons");
                        if (buttons) {
                            buttons?.parentNode?.removeChild(buttons);
                        }
                        pri.document.write((modalWrp as HTMLElement).innerHTML);
                        pri.document.close();
                        pri.focus();
                        pri.print();
                    }
                },
            },
            {
                text: process.env.REACT_APP_LANG === 'en' ? 'Send by email' : 'Отправить на почту',
                disabled: true,
                onClick: () => {
                    setModalEmail(true);
                },
            }
        ],
        items: statisticsData.items
    };

    const [items, setItems] = useState(statisticsData.items);

    useEffect(() => {
        setBaseAuth(baseStateAuth);

        if (!baseStateAuth) return;
        getItems();
        setInterval(() => {
            getItems();
        }, 15000);

    }, [baseStateAuth]);

    const getItems = () => {
        axios.get('/local/ajax/statistic.php').then((response) => {
            if (response.data && response.data.items) {
                setItems(response.data.items);
            }
        }).catch(e => {
            console.error(e);
        });
    }

    return (
        <Layout>
            {isAuth ?
                <>
                <div className='statistic' ref={statisticRef}>
                    <div className='statistic__header'>
                        <h1 className='statistic__title'>{statistic.title}</h1>
                        <div className='statistic__buttons'>
                            { statistic.buttons.map((button) => (
                                <Button {...button} isOrange={true} key={button.text}/>
                            )) }
                        </div>
                    </div>
                    <div className='statistic__content'>
                        { items && items.map((item: any, i) => (
                            <Card key={JSON.stringify(item)} {...item} />
                        )) }
                    </div>
                </div>
                <iframe id="ifmcontentstoprint" title="print test" style={{height: "0px", width: "0px", top: 0, position: "absolute", visibility: "hidden"}}></iframe>
                { showModalEmail && <Modal {...modalEmail}></Modal>}
                </>
            :
                <div>
                    <Modal {...modal}/>
                </div>
            }
        </Layout>
    );
}

export default Index;
