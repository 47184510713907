import React from 'react'
import Layout from '../layouts/base';
import { Modal, ModalProps } from '../components/modal';
import { Aside } from '../components/aside';
import PerfectScrollbar from 'perfect-scrollbar';
import axios from 'axios';
import '../styles/pages/test.scss';
import * as PIXI from 'pixi.js'

import data1 from '../data/test_3.json';
import data2 from '../data/result_3.json';
import data3 from '../data/survey_3.json';
import cn from 'classnames';
import { ReactSVG } from 'react-svg';
import { Button } from '../components/button';
import { Preloader } from '../components/preloader';

const questionData = data1[process.env.REACT_APP_LANG || 'ru'];
const resultData = data2[process.env.REACT_APP_LANG || 'ru'];
const surveyData = data3[process.env.REACT_APP_LANG || 'ru'];

const videoFileUrls = [
  "./assets/video/test3/01-seating-down.mp4",
  "./assets/video/test3/02-waiting.mp4",
  "./assets/video/test3/05-stomach.mp4",
  "./assets/video/test3/03-agree.mp4",
  "./assets/video/test3/06-sitDown.mp4",
  "./assets/video/test3/07-up.mp4",
  "./assets/video/test3/08-negation.mp4",
  "./assets/video/test3/09-agree_joined.mp4",
  "./assets/video/test3/04-waiting.mp4",
  "./assets/video/test3/10-waiting.mp4"
];

type TestState = {
  isHiddenAside?: boolean
  currentOption: {
    src: string,
    text: string,
    number: number,
    isLoop?: boolean
  },
  textResult: string,
  testResult: any[],
  testCardSwiper: any[],
  modal: ModalProps,
  modalEmail: ModalProps,
  staging: any,
  currentStage: string,
  question: string | number,
  result: ModalProps,
  redirect: ModalProps,
  timer: number,
  currentCard: {
    src: string,
    alt: string
  },
  data: object,
  showModalEmail: boolean,
  isTest: boolean;
  isOpenTest: boolean
  isSwiper: boolean
  initialSlide: number
  isHiddenViewedCard: boolean
  isOpenPreloader: boolean
}

export default class Index extends React.Component<{}, TestState> {
  private testRef: React.RefObject<HTMLDivElement> | null;
  private canvasRef: React.RefObject<HTMLDivElement>;
  private scrollbar: any;
  private textures: PIXI.Texture[];
  private app: PIXI.Application | null = null;
  private videoInst: any = null

  constructor(props) {
    super(props);
    this.testRef = React.createRef();
    this.canvasRef = React.createRef();
    this.state = {
      isTest: false,
      isOpenTest: false,
      isHiddenAside: true,
      isSwiper: false,
      initialSlide: 0,
      isHiddenViewedCard: true,
      isOpenPreloader: true,
      currentOption: {
        src: '',
        text: '',
        number: 0,
      },
      textResult: '',
      testResult: [],
      testCardSwiper: [],
      modal: {
        isMedium: true,
        title: process.env.REACT_APP_LANG === 'en' ? 'Hello, ladies and gentlemen!<br/>We invite you to take part in the interactive quest during which you will be able to carry out a full diagnosis of a patient of gastroenterological profile:' : 'Добрый день!<br/>Предлагаем принять участие в интерактивном квесте, в ходе которого Вы сможете провести полноценную диагностику пациента гастроэнтерологического профиля:',
        text: process.env.REACT_APP_LANG === 'en' ? '<ul><li>An initial consultation will allow you to collect the data concerning complaints, history of life and present illness of a patient, and afterwards you will be able to suggest a diagnosis and assign an empiric treatment.</li><li>The return visit will help you to correct the diagnosis and assign a treatment based on the results of the examination. Hope you will enjoy!</li></ul>' : '<ul><li>Первичный прием пациента позволит Вам получить данные о жалобах, анамнезе жизни и болезни пациента, после чего Вы сможете предположить диагноз и назначить эмпирическую терапию.</li><li>Повторный приём поможет скорректировать диагноз и назначить лечение на основе результатов обследования. Надеемся, Вам будет интересно!</li></ul>',
        button: {
          text: process.env.REACT_APP_LANG === 'en' ? 'Start' : 'Начать',
          isArrow: true,
          onClick: () => {
            this.setState((state) => {
              return {currentStage: state.staging.START};
            }, () => {
              this.loadVideo(videoFileUrls[0]);
            });
          }
        }
      },
      result: {
        isLarge: true,
        navigation: true,
        buttons: [
          {
            text: process.env.REACT_APP_LANG === 'en' ? 'Download PDF' : 'Скачать PDF',
            onClick: () => {
              const content = this.testRef?.current?.querySelector<HTMLElement>('.modal__text');
              var modalWrp = content?.cloneNode(true);
              if (!modalWrp) return;
              var elem = this.testRef?.current?.nextElementSibling;

              const isIFrame = (input: Element | HTMLElement | null): input is HTMLIFrameElement =>
                input !== null && input.tagName === 'IFRAME';

              if (modalWrp && elem && isIFrame(elem)) {
                var pri = elem.contentWindow;
                if (!pri) return;
                pri.document.open();
                var divs = (modalWrp as HTMLElement).getElementsByTagName("div");
                if (divs) {
                  [].slice.call(divs).forEach((div: HTMLElement) => {
                    div?.parentNode?.removeChild(div);
                  })
                }
                pri.document.write((modalWrp as HTMLElement).innerHTML);
                pri.document.close();
                pri.focus();
                pri.print();
              }
            },
            isOrange: true
          },
          {
            text: process.env.REACT_APP_LANG === 'en' ? 'Send by email' : 'Отправить на почту',
            onClick: () => {
              this.setState({showModalEmail: true});
            },
            disabled: true,
            isOrange: true
          },
          {
            text: process.env.REACT_APP_LANG === 'en' ? 'Complete' : 'Завершить прием',
            onClick: () => {
              window.location.reload();
            },
            isOrange: true
          }
        ]
      },
      redirect: {
        title: process.env.REACT_APP_LANG === 'en' ? 'The results of the examination are ready' : 'Готовы результаты обследований',
        subtitle: process.env.REACT_APP_LANG === 'en' ? 'The patient will be back for the return visit soon' : 'Пациент скоро прибудет на повторный прием',
        button: {
          text: process.env.REACT_APP_LANG === 'en' ? 'Look' : 'Смотреть',
          isArrow: true,
          onClick: () => {
            this.setState((state) => {
              return {currentStage: state.staging.SURVEY};
            })
          }
        }
      },
      staging: {
        PRE_START: "PRE_START",
        START: "START",
        REDIRECT: "REDIRECT",
        SURVEY: "SURVEY",
        RESULT: "RESULT",
      },
      currentStage: "PRE_START",
      currentCard: {
        src: '',
        alt: '',
      },
      question: 0,
      timer: 3000,
      data: {},
      showModalEmail: false,
      modalEmail: {
        btnClose: () => {
          this.setState({showModalEmail: false})
        },
        title: process.env.REACT_APP_LANG === 'en' ? 'Write your email' : 'Укажите адрес вашей почты',
        form: {
          action: "#",
          method: "GET",
          fields: [
            {
              type: 'email',
              name: 'email',
              placeholder: process.env.REACT_APP_LANG === 'en' ? 'Your email' : 'Ваша почта'
            },
            {
              type: 'button',
              text: process.env.REACT_APP_LANG === 'en' ? 'To send' : 'Отправить'
            }
          ],
          onSubmit: (e: any) => {
            const formData: Record<string, unknown> = {};
            e.preventDefault();
            const errors: any = [];

            const formElems = [].slice.call(e.target.elements).filter((elem: any) => elem.tagName !== 'BUTTON');
            formElems.forEach((item: any) => {
              if (item.validity.valid && item.value) {
                item.parentElement.removeAttribute('data-error');
                formData[item.name] = item.value;
              } else {
                item.parentElement.setAttribute('data-error', true);
                errors.push(item.parentElement);
              }
            });

            const content = this.testRef?.current?.querySelector<HTMLElement>('.modal__text');

            const modalWrp = content?.cloneNode(true);
            if (!modalWrp) return;

            let divs = (modalWrp as HTMLElement)?.getElementsByTagName("div");
            if (divs) {
              [].slice.call(divs).forEach((div: HTMLElement) => {
                div?.parentNode?.removeChild(div);
              })
            }

            if (errors.length === 0) {
              this.setState({showModalEmail: false});
            }

            const data = {
              "testName": "test5",
              "email": formData.email,
              "description": (modalWrp as HTMLElement)?.innerHTML,
            }

            fetch('/testpdf/pdfscript.php', {
              "method": "POST",
              "headers": {
                "Content-type": "application/json; charset=utf-8"
              },
              "body": JSON.stringify(data)
            }).then(response => response.json())
            .catch((error) =>{
              console.log(error);
            })

          }
        }
      },
    }

    this.textures = [];
  }

  // текстуры в массив, контроль хода загрузки, оптимизация видосов
  loadVideo(src?, loop?, testName?: string) {
    if (this.app) {
      if (this.videoInst) {
        this.videoInst.pause();
        this.videoInst = null
      }

      if (testName !== 'test-sitDown') {
        if (this.canvasRef.current) {
          this.canvasRef.current.style.filter = 'blur(0px)'
          this.setState({isOpenTest: false, isTest: false})
        }
      }

      const texture = this.textures.filter((item) => {
        const video = item?.baseTexture.resource as any;

        if (item?.textureCacheIds[0] === src) {
          return item;
        } else if (video.source.getElementsByTagName("source")[0].src.includes(src.slice(1))) {
          video.source.setAttribute('webkit-playsinline', '');
          video.source.setAttribute('playsinline', '');
          video.source.setAttribute('crossorigin', '');
          return item;
        }
      })[0];

      const resource = texture.baseTexture.resource as any;

      this.videoInst = resource.source;

      const videoSprite = new PIXI.Sprite(texture);
      videoSprite.width = this.app.screen.width;
      videoSprite.height = this.app.screen.height;
      while (this.app.stage.children.length > 2) {
        this.app.stage.removeChild(this.app.stage.children[0]);
      }
      this.app.stage.addChild(videoSprite);
      resource.source.play();
      resource.source.onended = () => {
        this.videoInst = null;

        if (this.state.currentStage === this.state.staging.SURVEY || this.state.currentStage === this.state.staging.REDIRECT) {
          return;
        }

        this.setState(() => {
          return {isHiddenAside: false};
        });

        const srcPrev = resource.source.querySelector('source').src;

        if (this.state.isTest) {
          if (this.canvasRef.current) {
            if (testName === 'test-sitDown') {
              this.canvasRef.current.style.filter = 'blur(15px)'
              this.setState({isOpenTest: true})
            }
          }
        } else {
          if (loop) {
            resource.source.loop = true;
            resource.source.play();
            return;
          } else if (srcPrev === src) {
            resource.source.play();
            return;
          } else {
            const num = this.randomInteger(1, 1);
            this.loadVideo(videoFileUrls[num]);
          }
        }
      }
    }
  }

  createReaction(e, loop?) {
    if (!e) return;
    this.loadVideo(e.src, loop);

    this.setState({currentOption: e});
  }

  nextStep(val: string | number, e?: any, dataForm?: object, name?: string) {
    if (val === 'reaction') {
      this.createReaction(e);
      return;
    }

    if (dataForm) {
      this.setState({data: Object.assign(this.state.data, dataForm)});
    }

    if (this.state.question === 7) {
      this.setState((state) => {
        return {currentStage: state.staging.REDIRECT, question: 8};
      });
    } else if (this.state.question === (questionData.length - 1)) {
      this.setResults();
      this.setState((state) => {
        return {currentStage: state.staging.RESULT};
      });
    } else {
      this.setState((state) => {
          return {question: Number(val) || ((val === 'prev') ? 0 : Number(state.question) + 1)};
        },
        () => {
          if (val === 8 || Number(this.state.question) > 8) {
            this.loadVideo(videoFileUrls[9], true);
          } else if (name) {
            const videoNumber = name === 'test-sitDown' ? 4 : 5;
            this.setState({isTest: true})
            this.loadVideo(videoFileUrls[videoNumber], false, name);
          } else {
            const num = this.randomInteger(1, 1);
            this.setState({
              currentOption: {
                src: videoFileUrls[num],
                text: '',
                number: this.state.currentOption.number,
              }
            });
          }
        }
      );
    }
  }

  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  surveyShow(val: string | number, e?: any, data?: object) {

    if (Number(val)) {
      this.setState((state) => {
        return {currentStage: state.staging.START};
      });
      this.nextStep(val, e, data);
      return;
    }
    const options = [].slice.call(document.querySelectorAll('.aside__option'));
    options.forEach((option: any) => {
      option.classList.remove('aside__option--opacity');
      if (option.dataset.id === e.currentTarget.dataset.id) {
        if (option !== e.currentTarget) {
          option.click();
        }
        this.setState({
          currentCard: {
            alt: surveyData.options.filter((opt) => opt.val === val)[0].text,
            src: surveyData.options.filter((opt) => opt.val === val)[0].img
          }
        });

        this.scrollbar.element.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        return;
      }
      option.classList.add('aside__option--opacity');
    });
    if (!this.testRef?.current) return;
    const cards = [].slice.call(this.testRef.current.querySelectorAll('.test__survey-item'));
    if (cards.length) {
      cards.forEach((card: any) => {
        card.classList.remove('active');
        if (card.dataset.id === val) {
          card.classList.add('active');
        }
      });
    }
  }

  testAllFound(arr: string[]) {
    return arr.includes('ClDifficile') && arr.includes('Pancreatic') && arr.includes('CalprotectinBlood') && arr.includes('Transglutaminase') && arr.includes('OAK');
  }

  foundOneTest(arr: string[]) {
    return arr.includes('ClDifficile') || arr.includes('Pancreatic') || arr.includes('CalprotectinBlood') || arr.includes('Transglutaminase') || arr.includes('OAK');
  }

  setResults() {
    let newText = resultData.text;
    Object.keys(this.state.data).forEach((key) => {
      if (!this.state.data[key] || !resultData[key]) return;
      if (Array.isArray(this.state.data[key])) {
        let allText = '';
        if (key === 'test') {
          const TESTS_GROUP = ['OAK', 'Transglutaminase', 'CalprotectinBlood', 'ClDifficile', 'Pancreatic'];

          if (this.state.data[key].includes('Ileocolonoscopy')) {
            allText += `<p>${resultData[key]['Ileocolonoscopy'].selected}</p>`;
          } else {
            allText += `<p>${resultData[key]['Ileocolonoscopy'].notSelected}</p>`;
          }

          const TEXTS = {
            isNotFoundText: false,
            currText: 'Также вы назначили пациенту: ',
            errorCurrText: 'Однако, согласно клиническим рекомендациям, утвержденным МЗ РФ от 2021г. также важно назначать лабораторные исследования, чтобы исключить такие заболевания как, '
          };

          if (this.testAllFound(this.state.data[key])) {
            allText += `<p>${resultData[key]['allTest']}</p>`;
          } else if (this.foundOneTest(this.state.data[key])) {
            TESTS_GROUP.forEach((item) => {
              if (this.state.data[key].includes(item)) {
                TEXTS.currText += resultData[key][item].selected;
              } else {
                TEXTS.isNotFoundText = true;
                TEXTS.errorCurrText += resultData[key][item]?.notSelected;
              }
            })

            allText += `<p>${TEXTS.currText.slice(0, TEXTS.currText.length - 2) + '.'}</p>`;

            if (TEXTS.isNotFoundText) {
              allText += `<p>${TEXTS.errorCurrText.slice(0, TEXTS.errorCurrText.length - 2) + '.'}</p>`;
            }
          } else {
            allText += `<p>${resultData[key]['notAllTest']}</p>`;
          }

          if (this.state.data[key].includes('BreathSIBO')) {
            allText += `<p>${resultData[key]['BreathSIBO'].selected}</p>`;
          } else {
            allText += `<p>${resultData[key]['BreathSIBO'].notSelected}</p>`;
          }
        } else {
          if (key === 'pv-cure' || key === 'fin-cure') {
            const list = {};
            let isFirst = true;
            const isFunCure = key === 'fin-cure';
            this.state.data[key].forEach((elem, i) => {
              if (!resultData[key][elem]) return;

              if (typeof resultData[key][elem] !== 'object') {
                const ruText = isFunCure ? '' : isFirst ? '<h4>Также Вы предложили пациенту</h4>' : '<p>'
                const enText = isFunCure ? '': isFirst ? '<h4>You also suggested to the patient</h4>' : '<p>'
                if (allText.length === 0) {
                  allText += resultData[key][elem];
                } else {
                  allText += ` ${process.env.REACT_APP_LANG === 'en' ? enText : ruText} ${resultData[key][elem]}`;
                  isFirst = false;
                }
              } else {
                if (list[resultData[key][elem].group]) {
                  list[resultData[key][elem].group].push(resultData[key][elem].text);
                } else {
                  list[resultData[key][elem].group] = [resultData[key][elem].text];
                }
              }
            });
            const ruText = isFunCure ? '<li>': isFirst ? '<h4>Также Вы предложили пациенту</h4>' : '<p>'
            const enText = isFunCure ? '<li>': isFirst ? '<h4>You also suggested to the patient</h4>' : '<p>'
            Object.keys(list).forEach((item) => {
              if (allText.length === 0 ) {
                allText += `${list[item].join(', ')}. ${resultData[`${key.split('cure')[0]}groups`][item]}`;
              } else {
                allText += `${process.env.REACT_APP_LANG === 'en' ? enText : ruText} ${list[item].join(', ')}. ${resultData[`${key.split('cure')[0]}groups`][item]}`;
              }
            });
          } else {
            this.state.data[key].forEach((elem, i) => {
              allText += i === 0 ? resultData[key][elem] : resultData[key][elem];
            });
          }
        }


        newText = newText.replace(`--${key}--`, allText);
      } else {
        newText = newText.replace(`--${key}--`, resultData[key][this.state.data[key]])
      }
    });


    this.setState({textResult: newText});

    this.sendResults();
  }

  sendResults() {
    const formData = new FormData();
    Object.keys(this.state.data).forEach((elem) => {
      if (elem === 'start' || elem === 'survey') return;
      formData.append(elem, Array.isArray(this.state.data[elem]) ? this.uniqResults(this.state.data[elem]) : this.state.data[elem]);
    });
    axios.post('/local/ajax/test3.php', formData).then((response) => {
      console.log(response);
    }).catch(e => {
      console.error(e);
    });
  }

  uniqResults(list) {
    return list.filter(function (item, pos) {
      return list.indexOf(item) === pos;
    });
  }

  openModalSwiper(idCard: string, index: number) {
    this.setViewedCard(idCard);
    let currIndex = 0;

    for (let i = 0; i <= this.state.testResult.length - 1; i++) {
      if (!(this.state.testResult[i].id === idCard)) {
        if (Array.isArray(this.state.testResult[i].img)) {
          currIndex += this.state.testResult[i].img.length;
        } else {
          currIndex += 1
        }
      } else {
        currIndex += 1
        break;
      }
    }

    this.setState({ isSwiper: true, initialSlide: currIndex - 1 })
  }

  setViewedCard(idCard: string) {
    const newTests: any [] = [];
    const cardViewed: any[] = []

    this.state.testResult.forEach((card) => {
      if (card.id === idCard) {
        card.isViewed = true;
        newTests.push(card)
      } else {
        newTests.push(card)
      }
    })

    newTests.forEach((card) => {
      if (card.isViewed) {
        cardViewed.push(card);
      }
    })

    if (cardViewed.length === newTests.length) {
      this.setState({ isHiddenViewedCard: false })
    }

    this.setState({ testResult: newTests })
  }

  componentDidUpdate(_, state) {
    if (this.state.currentStage === this.state.staging.SURVEY && state !== this.state) {
      const survey = this.testRef?.current?.querySelector('.test__survey-cards');
      if (!survey) return;

      if (window.innerWidth >= 1280) {
        this.scrollbar = new PerfectScrollbar(survey, {
          maxScrollbarLength: 80
        });
      }

      if (!this.state.testResult.length) {
        const tests: any[] = [];

        this.state.data[surveyData.id].forEach((item: string) => {
          const test = surveyData.options.find((option) => option.id === item);
          if (test) {
            tests.push(test);
          }
        });

        this.setState({testResult: tests, testCardSwiper: tests});
      }
    }
  }

  componentWillUnmount() {
    if (this.scrollbar) {
      this.scrollbar.destroy();
      this.scrollbar = null;
    }
  }

  setCanvasWH(app) {
    if (window.innerWidth * 0.56 < window.innerHeight) {
      app.view.width = window.innerHeight / 0.56;
      app.view.height = window.innerHeight;
      app.screen.width = window.innerHeight / 0.56;
      app.screen.height = window.innerHeight;
    } else {
      app.view.width = window.innerWidth;
      app.view.height = window.innerWidth * 0.56;
      app.screen.width = window.innerWidth;
      app.screen.height = window.innerWidth * 0.56;
    }
  }

  componentDidMount() {
    const canvas = document.querySelector('#canvas');
    this.app = new PIXI.Application({backgroundAlpha: 0});
    const loaderPixi = PIXI.Loader.shared;

    const firstSrc = videoFileUrls[0];
    loaderPixi.add({
      name: 'firstSrc',
      url: firstSrc,
      crossOrigin: true
    })

    loaderPixi.load((loader, resources) => {
      const firstTexture =  PIXI.Texture.from(resources.firstSrc.data);
      this.textures.push(firstTexture)

      videoFileUrls.slice(1).forEach((src) => {
        const texture = PIXI.Texture.from(src);
        (texture.baseTexture.resource as any).autoPlay = false;
        this.textures.push(texture);
      });

      this.setState({ isOpenPreloader: false })
    });

    if (canvas) {
      canvas.appendChild(this.app.view);
    }
    this.setCanvasWH(this.app);
    this.app.resize();

    window.addEventListener('resize', () => {
      if (!this.app) return;
      this.setCanvasWH(this.app);
      this.app.resize();
    });
  }

  render() {
    return (
      <Layout>
        <div className='test test--girl-black' ref={this.testRef}>
          {this.state.currentStage !== this.state.staging.RESULT &&
              <>
                <div id="canvas"
                     ref={this.canvasRef}
                     style={this.state.currentStage === this.state.staging.SURVEY ? {visibility: 'hidden'} : {}}></div>
              </>
          }
          {(this.state.currentStage === this.state.staging.START && (this.state.currentOption.text || questionData[this.state.question]?.reaction)) &&
              <div className={cn('test__reaction test__reaction--new', { 'test__reaction--visible': questionData[this.state.question].id === 'pv-pred-diagnosis' })}
                   data-point={this.state.currentOption.number}>{this.state.currentOption.text || questionData[this.state.question]?.reaction}</div>
          }
          {this.state.isOpenPreloader ? <Preloader /> : this.state.currentStage === this.state.staging.PRE_START && <Modal {...this.state.modal} isHeading={true} />}
          {this.state.currentStage === this.state.staging.START &&
              <Aside
                {...questionData[this.state.question]}
                className={cn({'aside--hidden': this.state.isHiddenAside})}
                isNewTest
                isOpenTest={this.state.isOpenTest}
                questions={questionData.length}
                question={this.state.question}
                onClickEvt={(val, e, data, name) => this.nextStep(val, e, data, name)}
              />
          }
          {this.state.currentStage === this.state.staging.REDIRECT && <Modal {...this.state.redirect} isNewRedirect />}
          {this.state.currentStage === this.state.staging.SURVEY &&
              <div className='test__survey test__survey--newTest'>
                {surveyData.title && (
                  <div className="test__survey-heading">
                    {surveyData.title}
                  </div>
                )}
                <div className="test__survey-cards">
                  {this.state.testResult && this.state.testResult.map((card, i) => (
                    <div
                      className={'test__survey-card'}
                      key={card.id}
                      onClick={() => this.openModalSwiper(card.id, i)}
                    >
                      <div className={cn('test__card-value', { 'test__card-value--viewed': card.isViewed })}>
                        <ReactSVG className={'test__card-icon'} src={`${process.env.REACT_APP_URL}assets/svg/viewedWhite.svg`}/>
                      </div>
                      <div className={'test__card-text'}>{card.text}</div>
                    </div>
                  ))}
                </div>
                {this.state.isSwiper && <Modal
                    isSwiper={this.state.isSwiper}
                    btnClose={() => this.setState({ isSwiper: false })}
                    dataImages={this.state.testCardSwiper}
                    changeSlide={(id) => this.setViewedCard(id)}
                    initialSlide={this.state.initialSlide}
                />}
                <Button
                    isArrow
                    text={'Поставить диагноз'}
                    className={cn('test__btn-next', {
                      'test__btn-next--hidden': this.state.isHiddenViewedCard,
                      'test__btn-next--swiper': this.state.isSwiper
                    })}
                    onClick={() => this.surveyShow(8, undefined, this.state.data)}
                />
              </div>
          }
          {this.state.currentStage === this.state.staging.RESULT &&
              <Modal {...this.state.result} isNewTest text={this.state.textResult} sources={resultData.sources}>
                {this.state.showModalEmail && <Modal {...this.state.modalEmail}></Modal>}
              </Modal>
          }
        </div>
        <iframe id="ifmcontentstoprint" title="print test"
                style={{height: "0px", width: "0px", top: 0, position: "absolute", visibility: "hidden"}}></iframe>
        <span className='id-test'>{process.env.REACT_APP_LANG === 'en' ? '2209981 (v1.0)' : 'RUS2299327 (v1.1)'}</span>
      </Layout>
    );
  }
}
