import React from "react";
import { Input, InputProps } from '../input/index';
import { Button, ButtonProps } from '../button/index';
import './style.scss';

export type FormEvents = {
    onSubmit?: (state: any) => void,
};

export type FormProps = {
    readonly action: string,
    readonly method: string,
	readonly fields: Array<InputProps|ButtonProps>;
} & FormEvents & FormPropsCallback

type FormState = {
	isSubmit: boolean,
}

interface FormPropsCallback {
    changeState?: (state: any) => void,
}

export class Form extends React.Component<FormProps, FormState>{
	constructor(props: FormProps) {
		super(props);
		this.state = {
			isSubmit: false,
        }
	}

    onSubmit(value: any) {
        if (this.props.onSubmit) {
            this.props.onSubmit(value);
        }
    }

    render() {
        return (
            <form className={"form"} action={this.props.action} method={this.props.method} onSubmit={(e) => this.onSubmit(e)}>
                { this.props.fields.map((field, i) => (
                    field.type !== 'button'
                        ?  <Input {...field} key={i}/>
                        : <Button {...field } key={i}/>
                )) }
            </form>
        );
    }
}
