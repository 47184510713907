import React from "react";
import Parser from "html-react-parser";
import { ReactSVG } from "react-svg";
import { Button, ButtonProps } from '../button';
import { Form, FormProps } from '../form';
import PerfectScrollbar from 'perfect-scrollbar';
import './style.scss';
import '../../styles/base.scss';
import cn from 'classnames';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';

export type ModalProps = {
  sources?: string;
  readonly icon?: string;
  readonly title?: string;
  readonly subtitle?: string;
  readonly text?: string;
  isLarge?: boolean;
  isMedium?: boolean;
  isSmall?: boolean;
  alignCenter?: boolean;
  readonly form?: FormProps;
  readonly buttons?: ButtonProps[];
  readonly button?: ButtonProps;
  readonly navigation?: boolean;
  readonly btnClose?: (state: any) => void
  readonly changeSlide?: (id: string) => void
  isSwiper?: boolean
  initialSlide?: number
  dataImages?: {
    text: string
    id: string
    val: string
    img: string | string[]
  }[]
  isNewTest?: boolean
  isShortBg?: boolean
  isShortBgWoman?: boolean
  isShortMan?: boolean
  isNewRedirect?: boolean
  isHeading?: boolean
}

type ModalState = {
  pagination: any[],
  activePagination: any,
  height: string | number
}

export class Modal extends React.Component<ModalProps, ModalState> {
  private modalRef: React.RefObject<HTMLDivElement> | null;
  private swiperRef: React.RefObject<HTMLDivElement> | null;
  private prevElRef: React.RefObject<HTMLDivElement> | null;
  private nextElRef: React.RefObject<HTMLDivElement> | null;

  private modalContent: any;
  private heightPart: number;
  private ps: any;

  constructor(props) {
    super(props);

    this.closeClick = this.closeClick.bind(this);
    this.modalRef = React.createRef();
    this.swiperRef = React.createRef();
    this.prevElRef = React.createRef();
    this.nextElRef = React.createRef();

    this.heightPart = 0;
    this.state = {
      pagination: [],
      activePagination: 0,
      height: '100%'
    }
  }

  closeClick(val: any) {
    if (this.props.btnClose) {
      this.props.btnClose(val);
    }
  }

  changeSlide(id: string) {
    if (this.props.changeSlide) {
      this.props.changeSlide(id);
    }
  }

  scrollContent(prev?: boolean) {
    this.modalContent.scroll({
      top: prev ? this.modalContent.scrollTop - this.heightPart : this.modalContent.scrollTop + this.heightPart,
      behavior: "smooth",
    });
  }

  componentDidMount() {
    if (this.swiperRef?.current) {
      const slides = Array.from(this.swiperRef.current.querySelectorAll<HTMLElement>('.swiper-slide'));
      if (slides.length && this.modalRef?.current) {
        const modalHeight =this.modalRef.current.offsetHeight
        const headingModalHeight = 164;

        slides.forEach((el) => {
          el.style.height = `${modalHeight - headingModalHeight}px`
          new PerfectScrollbar(el, {
            suppressScrollX: true
          });
        })
      }
    }

    const modalNavigation = this.modalRef?.current?.querySelector('.js-navigation');
    const content = this.modalRef?.current?.querySelector('.modal__content');
    this.modalContent = this.modalRef?.current?.querySelector('.modal__text');
    if (this.modalContent && modalNavigation) {
      const modalScroll = this.modalContent?.scrollHeight;
      const modalHeight = this.modalContent?.getBoundingClientRect().height;
      const paginationCount = Math.ceil(modalScroll / modalHeight);
      this.setState({pagination: new Array(paginationCount).fill(1), activePagination: 0});

      this.ps?.destroy();
      this.ps = null;
      this.ps = new PerfectScrollbar(this.modalContent, {
        maxScrollbarLength: 110
      });
      this.heightPart = (this.ps.contentHeight - this.ps.containerHeight) / paginationCount;

      this.modalContent.addEventListener('ps-scroll-y', (e) => {
        if (this.modalContent.scrollTop < this.heightPart) {
          this.setState({activePagination: 0});
        } else {
          for (let i = 1; i < paginationCount; i++) {
            if (this.modalContent.scrollTop > this.heightPart * i) {
              this.setState({activePagination: i});
            }
          }
        }

      });
    } else {
      if (this.modalContent) {
        this.ps?.destroy();
        this.ps = null;
        this.ps = new PerfectScrollbar(this.modalContent, {
          maxScrollbarLength: 110
        });
      }

      if (!content || !this.modalRef?.current?.classList.contains('modal--medium')) return;
      this.setState({height: content.parentElement?.clientHeight ? content.parentElement?.clientHeight - 20 : '100%'});

      window.addEventListener("resize", () => {
        this.setState({height: content.parentElement?.clientHeight ? content.parentElement?.clientHeight - 20 : '100%'});
      });
    }
  }

  componentDidUpdate(props, state) {
    if (state !== this.state && !this.ps) {
      const content = this.modalRef?.current?.querySelector('.modal__content');
      if (!content) return;
      this.ps = new PerfectScrollbar(content, {
        maxScrollbarLength: 110
      });
    }
  }

  render() {
    return (
      <div
        className={cn('modal', {
          'modal--large': this.props.isLarge,
          'modal--medium': this.props.isMedium,
          'modal--small': this.props.isSmall,
          'modal--center': this.props.alignCenter,
          'modal--swiper': this.props.isSwiper,
          'modal--shortBg': this.props.isShortBg,
          'modal--shortBgWoman': this.props.isShortBgWoman,
          'modal--shortMan': this.props.isShortMan
        })}
        ref={this.modalRef}>
        {this.props.isSwiper ? (
          <>
            <div className={'modal__swiper-back'} onClick={this.closeClick}>Все обследования</div>
            <div className={'modal__swiper-wrapper'}>
              <Swiper
                ref={this.swiperRef}
                slidesPerView={'auto'}
                initialSlide={this.props.initialSlide ?? 0}
                centeredSlides
                slideToClickedSlide
                spaceBetween={212}
                navigation={{
                  nextEl: this.nextElRef?.current,
                  prevEl: this.prevElRef?.current,
                  disabledClass: 'modal__swiper-navigation--disabled'
                }}
                onSlideChange={(swiper) => this.changeSlide(swiper.slides[swiper.activeIndex].dataset.id)}
                modules={[Navigation]}
                onSwiper={(swiper) => {
                  setTimeout(() => {
                    swiper.params.navigation.prevEl = this.prevElRef?.current
                    swiper.params.navigation.nextEl = this.nextElRef?.current

                    swiper.navigation.destroy()
                    swiper.navigation.init()
                    swiper.navigation.update()
                  })
                }}
              >
                {this.props.dataImages && this.props.dataImages?.map((slide, i) => {
                  if (Array.isArray(slide.img)) return (
                    slide.img.map((src, i) => (
                      <SwiperSlide key={`${slide.id}-${i}`} data-id={slide.id} className={'modal__swiper-slide ps'}>
                          <img src={src} alt={slide.text} />
                      </SwiperSlide>
                    ))
                  )

                 return (
                   <SwiperSlide key={i} data-id={slide.id} className={'modal__swiper-slide ps'}>
                     <img src={slide.img} alt={slide.text} />
                   </SwiperSlide>
                 )
                })}
              </Swiper>
              <div
                className={'modal__swiper-navigation'}
                ref={this.prevElRef}
              >
                <ReactSVG className={'modal__swiper-icon'} src={`${process.env.REACT_APP_URL}assets/svg/arrowLeft.svg`}/>
              </div>
              <div
                className={'modal__swiper-navigation modal__swiper-navigation--next'}
                ref={this.nextElRef}
              >
                <ReactSVG className={'modal__swiper-icon'} src={`${process.env.REACT_APP_URL}assets/svg/arrowLeft.svg`}/>
              </div>
            </div>
          </>
        ) : (
          <div className={cn('modal__wrapper', { 'modal__wrapper--new-redirect': this.props.isNewRedirect })}>
            <div className={'modal__content'} style={{maxHeight: this.state.height}}>
              {this.props.btnClose &&
                  <button className="modal__close" onClick={this.closeClick}>
                    <ReactSVG className="modal__close-icon" src={`${process.env.REACT_APP_URL}assets/svg/close.svg`}/>
                  </button>
              }
              {this.props.icon && <ReactSVG className="modal__icon" src={this.props.icon}/>}
              {this.props.title && <p className={cn('modal__title', { 'modal__title--isHeading': this.props.isHeading })}>{Parser(this.props.title)}</p>}
              {this.props.subtitle && <p className={'modal__subtitle'}>{Parser(this.props.subtitle)}</p>}
              {this.props.text &&
                  <div className={cn('modal__text', { 'modal__text--new': this.props.isNewTest })}>
                    {Parser(this.props.text)}
                    {this.props.sources ?
                      <div>
                        <button className='modal__accordeon' onClick={(e) => {
                          const btn = e.currentTarget;
                          const list = btn.nextElementSibling;
                          btn.classList.toggle('active');
                          if (!list) return;
                          if (btn.classList.contains('active')) {
                            (list as HTMLElement).style.maxHeight = `${btn.nextElementSibling?.scrollHeight}px`;
                          } else {
                            (list as HTMLElement).style.maxHeight = '0px';
                          }
                        }}>{process.env.REACT_APP_LANG === 'en' ? 'Sources' : 'Источники'}</button>
                        {Parser(this.props.sources)}
                      </div>
                      : ''
                    }
                    {this.props.buttons &&
                        <div className={'modal__buttons'}>
                          {this.props.buttons.map((button, i) => (
                            <Button {...button} key={`btn_in_text-${i}`}/>
                          ))}
                        </div>
                    }
                  </div>
              }
              {this.props.form &&
                  <Form action={this.props.form.action} method={this.props.form.method} fields={this.props.form.fields}
                        onSubmit={this.props.form.onSubmit}/>
              }
              {this.props.button && <Button {...this.props.button} className={'modal__btn'} />}
            </div>
            {this.props.children}
          </div>
        )}
        {this.props.navigation && !this.props.isNewTest &&
            <div className={'modal__navigation js-navigation'}>
              <div className={'modal__navigation-arrow'} onClick={() => this.scrollContent(true)}></div>
              <div className={'modal__navigation-arrow'} onClick={() => this.scrollContent()}></div>
            </div>
        }
        {this.props.navigation && !this.props.isNewTest &&
            <div className={'modal__pagination js-pagination'}>
              {this.state.pagination.map((elem, i) => (
                <span key={`pagination-${i}`} className={i === this.state.activePagination ? 'active' : ''}></span>
              ))}
            </div>
        }
      </div>
    );
  }
}
