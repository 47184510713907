import React from "react";
import './style.scss';
import { Chart, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

Chart.register(...registerables, ChartDataLabels);

export type CardProps = {
    readonly title: string,
    readonly select: any[];
    readonly indicators?: any[];
}

type CardState = {
    chartWrp: any,
	inPercent: boolean,
    inDiagramma: boolean,
    isOpen: boolean,
    isCardViewed: boolean,
    checkedVal: {
        text: string,
        val: string
    },
    colors: string[],
}

export class Card extends React.Component<CardProps, CardState>{
    private config;

	constructor(props: CardProps) {
		super(props);
		this.state = {
            inDiagramma: this.props.select.find(elem => elem.checked).value === 'diagramma',
            inPercent: this.props.select.find(elem => elem.checked).value === 'percent',
            isOpen: false,
            isCardViewed: false,
            chartWrp: null,
            checkedVal: {
                text: this.props.select.find(elem => elem.checked).text || '',
                val: this.props.select.find(elem => elem.checked).value || '',
            },
            colors: ['#0EA839', '#C57339', '#A80E0E'],
        }

        this.config = {
            type: 'doughnut',
            data: {
                labels: this.props.indicators?.map((item) => item.name),
                datasets: [{
                    data: this.props.indicators?.map((item, i) => item.percent.split('%')[0]),
                    backgroundColor: this.props.indicators?.map((_, i) => this.state.colors[i]),
                    borderWidth: 0,
                }]
            },
            options: {
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    usePointStyle: true,
                    pointStyle: 'rect',
                    textAlign: 'center',
                    padding: 20
                  },
                },
                datalabels: {
                    display: true,
                    align: 'center',
                    color: '#fff',
                    borderRadius: 3,
                    font: {
                      size: 18,
                    },
                    formatter: (value) => {
                        return value + '%';
                    }
                }
              }
            },
        };
    }


    setDiagramma() {
        const ctx: HTMLElement|HTMLCanvasElement|undefined|null = document.getElementById('myChart');
        if (ctx && (ctx as HTMLCanvasElement).getContext("2d")) {
            if (this.state.chartWrp) {
                this.state.chartWrp.destroy();
                this.setState({chartWrp: null});
            }
            const elem = new Chart((ctx as HTMLCanvasElement).getContext("2d") as any, this.config);
            this.setState({chartWrp: elem});
        }
    }

    componentDidMount() {
        if (this.state.inDiagramma) {
            this.setDiagramma();
        }
    }

    componentDidUpdate(_, state) {
        if (!this.state.isOpen && this.state.inDiagramma && this.state.inDiagramma !== state.inDiagramma) {
            this.setDiagramma();
        }
    }

    changeViewCard = () => {
        this.setState({ isCardViewed: !this.state.isCardViewed });
    }

    render() {
        return (
		 <div
            className={[
                'card',
                this.state.inPercent ? 'card--range' : '',
                this.state.isCardViewed ? 'card--opened' : '',
            ].join(' ')}
        >
             <div className={'card__header'}>
                <p className={'card__title'} onClick={this.changeViewCard} >{this.props.title}</p>
                <button className='card__arrow' type='button' onClick={this.changeViewCard} >
                    <svg fill="#ffffff" height="20px" width="20px" viewBox="0 0 330 330">
                        <path d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
                            c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
                            s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"/>
                    </svg>
                </button>
                <div className={'card__select'}>
                    <button
                        className={`card__select-option ${this.state.isOpen ? 'card__select-option--open' : ''}`}
                        onClick={
                            () => {this.setState((state) => {
                                return {isOpen: !state.isOpen};
                            })}
                        }>{this.state.checkedVal.text}</button>
                    { this.state.isOpen && <div className={'card__options'}>
                        { this.props.select.map((elem, i) => (
                            <label className={'card__option'} key={elem.name + i}>
                                <input type='radio' name={elem.name} value={elem.value} key={elem.text} onChange={
                                    (e) => {
                                        this.setState({
                                            checkedVal: {
                                                text: elem.text,
                                                val: elem.value
                                            },
                                            isOpen: false,
                                            inPercent: e.target.value === 'percent',
                                            inDiagramma: e.target.value === 'diagramma',
                                        });
                                    }}/>
                                {elem.text}
                            </label>
                        ))}
                    </div> }
                </div>
             </div>
             <div className="card__content-wrapper">
                <div className='card__content'>
                    { this.state.inDiagramma ?
                        <div className="card__diagramma">
                            <canvas id="myChart"></canvas>
                        </div> :
                    <>
                        { this.props.indicators && this.props.indicators.map((indicator, i) => (
                            <div className={`card__row ${indicator.isIncorrect ? 'card__row--incorrect' : ''}`} key={i}>
                                <p className='card__row-name'>{indicator.name}</p>
                                { this.state.inPercent &&
                                    <span className='card__row-range'>
                                        <span style={{maxWidth: indicator.percent}}></span>
                                    </span>
                                }
                                <span className='card__row-value'>{this.state.inPercent ? `${Math.round(Number(indicator.percent.split('%')[0]))}%` : indicator.points}</span>
                            </div>
                        ))}
                    </>}
                </div>
             </div>
		</div>
        );
    }
}
