import React from 'react'
import Layout from '../layouts/base';
import { Modal, ModalProps } from '../components/modal';
import { Aside } from '../components/aside';
import PerfectScrollbar from 'perfect-scrollbar';
import axios from 'axios';
import * as PIXI from 'pixi.js'

import '../styles/pages/test.scss';

import data1 from '../data/test_short_5.json';
import data2 from '../data/survey_short_5.json';
import cn from 'classnames';
import { Preloader } from '../components/preloader';
import { ReactSVG } from 'react-svg';
import { Button } from '../components/button';

const questionData = data1[process.env.REACT_APP_LANG || 'ru'];
const surveyData = data2[process.env.REACT_APP_LANG || 'ru'];

const videoFileUrls = [
  "./assets/video/test5/01-seating-down.mp4",
  "./assets/video/test5/02-waiting.mp4",
  "./assets/video/test5/05-fatigue.mp4",
  "./assets/video/test5/03-agree.mp4",
  "./assets/video/test5/06-sitDown.mp4",
  "./assets/video/test5/07-up.mp4",
  "./assets/video/test5/08-negation.mp4",
  "./assets/video/test5/09-agree_joined.mp4",
  "./assets/video/test5/04-thoughtfulness.mp4",
  "./assets/video/test5/10-waiting.mp4"
];

type TestState = {
  isHiddenAside?: boolean
  isTest: boolean;
  isOpenTest: boolean
  isAuth: boolean,
  login: ModalProps,
  currentCard: {
    src: string,
    alt: string
  },
  currentOption: {
    src: string,
    text: string,
    number: number,
    isLoop?: boolean
  },
  modal: ModalProps,
  staging: any,
  currentStage: string,
  question: string | number,
  pre_survey: ModalProps,
  data: object,
  isOpenPreloader: boolean,
  testResult: any[],
  testCardSwiper: any[]
  isSwiper: boolean
  initialSlide: number
  isHiddenViewedCard: boolean
}

export default class Index extends React.Component<{}, TestState> {
  private testRef: React.RefObject<HTMLDivElement> | null;
  private canvasRef: React.RefObject<HTMLDivElement>;
  private scrollbar: any;
  private app: PIXI.Application | null = null;
  private textures: PIXI.Texture[];
  private videoInst: any = null

  constructor(props) {
    super(props);
    this.testRef = React.createRef();
    this.canvasRef = React.createRef();
    this.state = {
      isTest: false,
      isOpenTest: false,
      isHiddenAside: true,
      isAuth: false,
      isOpenPreloader: true,
      isSwiper: false,
      isHiddenViewedCard: true,
      initialSlide: 0,
      login: {
        title: process.env.REACT_APP_LANG === 'en' ? 'Welcome!' : 'Добро пожаловать! ',
        subtitle: process.env.REACT_APP_LANG === 'en' ? 'Enter your access code below.' : 'Для входа на портал введите код. ',
        form: {
          action: "#",
          method: "GET",
          fields: [
            {
              type: 'text',
              name: 'code',
              placeholder: process.env.REACT_APP_LANG === 'en' ? 'Access code' : 'Код доступа'
            },
            {
              type: 'button',
              text: process.env.REACT_APP_LANG === 'en' ? 'Enter' : 'Войти'
            }
          ],
          onSubmit: (e: any) => {
            const authData = new FormData();
            let errors: any = [];
            e.preventDefault();

            const formElems = [].slice.call(e.target.elements).filter((elem: any) => elem.tagName !== 'BUTTON');
            formElems.forEach((item: any) => {
              if (item.validity.valid && item.value) {
                item.parentElement.removeAttribute('data-error');
                authData.append(item.name, item.value);
              } else {
                item.parentElement.setAttribute('data-error', true);
                errors.push(item.parentElement);
              }
            });


            if (!errors.length) {
              errors = [];
              axios.post('/local/ajax/auth.php', authData).then((response) => {
                if (response.data.status !== 'false') {
                  this.setState({isAuth: true});
                } else {
                  const codeEl: any = formElems.filter((item: any) => item.name === 'code')[0];
                  codeEl.parentElement.setAttribute('data-error', true);
                  errors.push(codeEl);
                }
              }).catch(e => {
                console.error(e);
                this.setState({isAuth: true});
              });
            } else {
              const codeEl: any = formElems.filter((item: any) => item.name === 'code')[0];
              codeEl.parentElement.setAttribute('data-error', true);
              errors.push(codeEl);
            }
          }
        }
      },
      currentOption: {
        src: '',
        text: '',
        number: 0,
      },
      modal: {
        isMedium: true,
        title: process.env.REACT_APP_LANG === 'en' ? 'Hello, ladies and gentlemen!<br/>We invite you to take part in the interactive quest during which you will be able to carry out a full diagnosis of a patient of gastroenterological profile:' : 'Добрый день!<br/>Предлагаем принять участие в интерактивном кейсе, в ходе которого Вы сможете провести полноценную диагностику пациента гастроэнтерологического профиля.',
        button: {
          text: process.env.REACT_APP_LANG === 'en' ? 'Start' : 'Начать',
          isArrow: true,
          onClick: () => {
            this.setState((state) => {
              return {currentStage: state.staging.START};
            }, () => {
              this.createCanvas();
              this.createReaction({
                src: videoFileUrls[0],
                text: '',
                number: 0,
              }, false);
            })
          }
        }
      },
      pre_survey: {
        isSmall: true,
        icon: `${process.env.REACT_APP_URL}assets/svg/like.svg`,
        title: process.env.REACT_APP_LANG === 'en' ? 'Thank you for participation! You will see the results of the test during the webinar. Please, return to the webinar.' : 'Благодарим за участие! Результаты теста Вы увидите в ходе вебинара. Вернитесь на вебинар.'
      },
      staging: {
        PRE_START: "PRE_START",
        START: "START",
        SURVEY: "SURVEY",
        PRE_SURVEY: "PRE_SURVEY",
      },
      currentStage: "PRE_START",
      currentCard: {
        src: '',
        alt: '',
      },
      question: 0,
      data: {
        test: ['OAK', 'FerritinSerum', 'AutoantibodiesSMA', 'BAK', 'BloodHBsAG', 'Ceruloplasmin', 'OBPEGDSFIBRO', 'BloodAlphaBeta']
      },
      testResult: [],
      testCardSwiper: []
    }
    this.textures = [];
  }

  loadVideo(src?, loop?, testName?: string) {
    if (this.app) {

      if (this.videoInst) {
        this.videoInst.pause();
        this.videoInst = null
      }

      if (testName !== 'test-sitDown') {
        if (this.canvasRef.current) {
          this.canvasRef.current.style.filter = 'blur(0px)'
          this.setState({isOpenTest: false, isTest: false})
        }
      }

      const texture = this.textures.filter((item) => {
        const video = item?.baseTexture.resource as any;

        if (item?.textureCacheIds[0] === src) {
          return item;
        } else if (video.source.getElementsByTagName("source")[0].src.includes(src.slice(1))) {
          video.source.setAttribute('webkit-playsinline', '')
          video.source.setAttribute('playsinline', '')
          video.source.setAttribute('crossorigin', '')
          return item
        }
      })[0];
      if (!texture) return;

      const resource = texture.baseTexture.resource as any;

      this.videoInst = resource.source;

      const videoSprite = new PIXI.Sprite(texture);
      videoSprite.width = this.app.screen.width;
      videoSprite.height = this.app.screen.height;
      while (this.app.stage.children.length > 2) {
        this.app.stage.removeChild(this.app.stage.children[0]);
      }
      this.app.stage.addChild(videoSprite);
      resource.source.play();

      resource.source.onended = () => {
        this.videoInst = null

        this.setState(() => {
          return {isHiddenAside: false};
        });

        const srcPrev = resource.source.querySelector('source').src;

        if (this.state.isTest) {
          if (this.canvasRef.current) {
            if (testName === 'test-sitDown') {
              this.canvasRef.current.style.filter = 'blur(15px)'
              this.setState({isOpenTest: true})
            }
          }
        } else {
          if (loop) {
            resource.source.loop = true;
            resource.source.play();
            return;
          } else if (srcPrev === src) {
            resource.source.play();
            return;
          } else {
            const num = this.randomInteger(1, 1);
            this.loadVideo(videoFileUrls[num]);
          }
        }
      }
    }
  }

  createReaction(e, loop?) {
    if (!e) return;
    this.loadVideo(e.src, loop);
    this.setState({currentOption: e});
  }

  surveyShow(val: string | number, e?: any, data?: object) {

    if (Number(val)) {
      this.setState((state) => {
        return {currentStage: state.staging.START};
      });
      this.nextStep(val, e, data);
      return;
    }
    const options = [].slice.call(document.querySelectorAll('.aside__option'));
    options.forEach((option: any) => {
      option.classList.remove('aside__option--opacity');
      if (option.dataset.id === e.currentTarget.dataset.id) {
        if (option !== e.currentTarget) {
          option.click();
        }
        this.setState({
          currentCard: {
            alt: surveyData.options.filter((opt) => opt.val === val)[0].text,
            src: surveyData.options.filter((opt) => opt.val === val)[0].img
          }
        });

        this.scrollbar.element.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
        return;
      }
      option.classList.add('aside__option--opacity');
    });
    if (!this.testRef?.current) return;
    const cards = [].slice.call(this.testRef.current.querySelectorAll('.test__survey-item'));
    if (cards.length) {
      cards.forEach((card: any) => {
        card.classList.remove('active');
        if (card.dataset.id === val) {
          card.classList.add('active');
        }
      });
    }
  }

  nextStep(val: string | number, e?: any, dataForm?: object, name?: string) {
    if (val === 'SURVEY') {
      this.setState((state) => {
        return {currentStage: state.staging.SURVEY};
      });

      return;
    }

    if (val === 'reaction') {
      this.createReaction(e);
      return;
    }
    if (dataForm) {
      this.setState({data: Object.assign(this.state.data, dataForm)});
    }
    if (this.state.question === 7) {
      this.setState((state) => {
        return {currentStage: state.staging.PRE_SURVEY, question: 8};
      });
      this.sendResults();
    } else {
      this.setState((state) => {
          return {question: Number(val) || ((val === 'prev') ? 0 : Number(state.question) + 1)};
        }, () => {
          if (name) {
            const videoNumber = name === 'test-sitDown' ? 4 : 5;
            this.setState({isTest: true})
            this.loadVideo(videoFileUrls[videoNumber], false, name);
          } else {
            const num = this.randomInteger(1, 1);
            this.createReaction({
              src: videoFileUrls[num],
              text: '',
              number: this.state.currentOption.number,
              isLoop: true,
            });
          }
        }
      );
    }
  }

  randomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  componentDidUpdate(_, state) {
    // if (this.state.currentStage === this.state.staging.SURVEY && state !== this.state) {
    //   const survey = this.testRef?.current?.querySelector('.test__survey-list');
    //   if (!survey) return;
    //   if (window.innerWidth >= 1280) {
    //     new PerfectScrollbar(survey, {
    //       maxScrollbarLength: 80
    //     });
    //   }
    // }

    if (this.state.currentStage === this.state.staging.SURVEY && state !== this.state) {
      const survey = this.testRef?.current?.querySelector('.test__survey-cards');
      if (!survey) return;

      if (window.innerWidth >= 1280) {
        this.scrollbar = new PerfectScrollbar(survey, {
          maxScrollbarLength: 80
        });
      }

      if (!this.state.testResult.length) {
        const tests: any[] = [];

        this.state.data[surveyData.id].forEach((item: string) => {
          const test = surveyData.options.find((option) => option.id === item);
          if (test) {
            tests.push(test);
          }
        });

        this.setState({testResult: tests, testCardSwiper: tests});
      }
    }
  }

  sendResults() {
    const formData = new FormData();
    Object.keys(this.state.data).forEach((elem) => {
      if (elem === 'start' || elem === 'survey') return;
      formData.append(elem, Array.isArray(this.state.data[elem]) ? this.uniqResults(this.state.data[elem]) : this.state.data[elem]);
    });
    axios.post('/local/ajax/test5.php', formData).then((response) => {
      console.log(response);
    }).catch(e => {
      console.error(e);
    });
  }

  uniqResults(list) {
    return list.filter(function (item, pos) {
      return list.indexOf(item) === pos;
    });
  }

  openModalSwiper(idCard: string, index: number) {
    this.setViewedCard(idCard);
    let currIndex = 0;

    for (let i = 0; i <= this.state.testResult.length - 1; i++) {
      if (!(this.state.testResult[i].id === idCard)) {
        if (Array.isArray(this.state.testResult[i].img)) {
          currIndex += this.state.testResult[i].img.length;
        } else {
          currIndex += 1
        }
      } else {
        currIndex += 1
        break;
      }
    }

    this.setState({ isSwiper: true, initialSlide: currIndex - 1 })
  }

  setViewedCard(idCard: string) {
    const newTests: any [] = [];
    const cardViewed: any[] = []

    this.state.testResult.forEach((card) => {
      if (card.id === idCard) {
        card.isViewed = true;
        newTests.push(card)
      } else {
        newTests.push(card)
      }
    })

    newTests.forEach((card) => {
      if (card.isViewed) {
        cardViewed.push(card);
      }
    })

    if (cardViewed.length === newTests.length) {
      this.setState({ isHiddenViewedCard: false })
    }

    this.setState({ testResult: newTests })
  }


  componentDidMount() {
    const loaderPixi = PIXI.Loader.shared;

    const firstSrc = videoFileUrls[0];
    loaderPixi.add({
      name: 'firstSrc',
      url: firstSrc,
      crossOrigin: true
    })

    loaderPixi.load((loader, resources) => {
      const firstTexture =  PIXI.Texture.from(resources.firstSrc.data);
      this.textures.push(firstTexture)

      videoFileUrls.slice(1).forEach((src) => {
        const texture = PIXI.Texture.from(src);
        (texture.baseTexture.resource as any).autoPlay = false;
        this.textures.push(texture);
      });

      this.setState({ isOpenPreloader: false })
    });
  }

  createCanvas() {
    const canvas = document.querySelector('#canvas');
    (canvas as HTMLCanvasElement).width = window.innerWidth;
    (canvas as HTMLCanvasElement).height = window.innerWidth * 0.56;

    this.app = new PIXI.Application({backgroundAlpha: 0});
    if (canvas) {
      canvas.appendChild(this.app.view);
    }
    this.setCanvasWH(this.app);
    this.app.resize();

    window.addEventListener('resize', () => {
      if (!this.app) return;
      this.setCanvasWH(this.app);
      this.app?.resize();
    });
  }

  setCanvasWH(app) {
    if (window.innerWidth * 0.56 < window.innerHeight) {
      app.view.width = window.innerHeight / 0.56;
      app.view.height = window.innerHeight;
      app.screen.width = window.innerHeight / 0.56;
      app.screen.height = window.innerHeight;
    } else {
      app.view.width = window.innerWidth;
      app.view.height = window.innerWidth * 0.56;
      app.screen.width = window.innerWidth;
      app.screen.height = window.innerWidth * 0.56;
    }
  }

  render() {
    return (
      <Layout>
        {this.state.isOpenPreloader ? <Preloader /> : !this.state.isAuth ? <Modal {...this.state.login}/> :
          <div className={cn('test', { 'test--short-test': this.state.currentStage !== this.state.staging.SURVEY })} ref={this.testRef}>
            <>
              <div id="canvas"
                   ref={this.canvasRef}
                   style={this.state.currentStage === this.state.staging.PRE_START || this.state.currentStage === this.state.staging.PRE_SURVEY || this.state.currentStage === this.state.staging.SURVEY ? {visibility: 'hidden'} : {}}></div>
            </>
            {(this.state.currentStage !== this.state.staging.SURVEY && (this.state.currentOption.text || questionData[this.state.question]?.reaction)) &&
                <div className={cn('test__reaction test__reaction--new', { 'test__reaction--visible': questionData[this.state.question].id === 'pv-pred-diagnosis' })}
                     data-point={this.state.currentOption.number}>{this.state.currentOption.text || questionData[this.state.question]?.reaction}</div>
            }
            {this.state.currentStage === this.state.staging.PRE_START && <Modal {...this.state.modal}/>}
            {this.state.currentStage === this.state.staging.START &&
                <Aside
                  {...questionData[this.state.question]}
                  className={cn({'aside--hidden': this.state.isHiddenAside})}
                  isNewTest
                  isOpenTest={this.state.isOpenTest}
                  questions={questionData.length}
                  question={this.state.question}
                  onClickEvt={(val, e, data, name) => this.nextStep(val, e, data, name)}
                />
            }
            {this.state.currentStage === this.state.staging.SURVEY &&
                <div className='test__survey test__survey--newTest'>
                  {surveyData.title && (
                    <div className="test__survey-heading">
                      {surveyData.title}
                    </div>
                  )}
                  <div className="test__survey-cards">
                    {this.state.testResult && this.state.testResult.map((card, i) => (
                      <div
                        className={'test__survey-card'}
                        key={card.id}
                        onClick={() => this.openModalSwiper(card.id, i)}
                      >
                        <div className={cn('test__card-value', { 'test__card-value--viewed': card.isViewed })}>
                          <ReactSVG className={'test__card-icon'} src={`${process.env.REACT_APP_URL}assets/svg/viewedWhite.svg`}/>
                        </div>
                        <div className={'test__card-text'}>{card.text}</div>
                      </div>
                    ))}
                  </div>
                  {this.state.isSwiper && <Modal
                      isSwiper={this.state.isSwiper}
                      btnClose={() => this.setState({ isSwiper: false })}
                      dataImages={this.state.testCardSwiper}
                      changeSlide={(id) => this.setViewedCard(id)}
                      initialSlide={this.state.initialSlide}
                  />}
                  <Button
                      isArrow
                      text={'Поставить диагноз'}
                      className={cn('test__btn-next', {
                        'test__btn-next--hidden': this.state.isHiddenViewedCard,
                        'test__btn-next--swiper': this.state.isSwiper
                      })}
                      onClick={() => this.surveyShow(6, undefined, this.state.data)}
                  />
                </div>
            }
            {this.state.currentStage === this.state.staging.PRE_SURVEY && <Modal {...this.state.pre_survey} isShortMan />}
          </div>}
        <span className='id-test'>{process.env.REACT_APP_LANG === 'en' ? '2316962 (v1.0)' : 'RUS2316962 (v1.0)'}</span>
      </Layout>
    );
  }
}
