import React, { FC, useEffect, useState } from "react";
import './style.scss';

interface IPreloader {
  className?: string
}

export const Preloader:FC<IPreloader> = () => {
  const [textTag, setTextTag] = useState('Звоним в регистратуру');

  useEffect(() => {
    const TOTAL_DURATION_ANIM = 15000
    const CHANGE_TAB = 5300

   let interval =  setInterval(() => {
      setTextTag(prev => {
        if (prev === 'Ждём очередь') return 'Звоним в регистратуру';

        return 'Ждём очередь';
      });
    }, CHANGE_TAB);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      interval = setInterval(() => {
        setTextTag(prev => {
          if (prev === 'Ждём очередь') return 'Звоним в регистратуру';

          return 'Ждём очередь';
        });
      }, CHANGE_TAB);
    }, TOTAL_DURATION_ANIM)

    return () => { clearInterval(interval); clearTimeout(timeout) }
  }, [])

  return (
    <div className={'preloader'}>
      <svg className="filter" version="1.1">
        <defs>
          <filter id="gooeyness">
            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
            <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 20 -10" result="gooeyness" />
            <feComposite in="SourceGraphic" in2="gooeyness" operator="atop" />
          </filter>
        </defs>
      </svg>
      <div className="preloader__wrapper">
        <div className="preloader__dots">
          <div className="preloader__dot" />
          <div className="preloader__dot" />
          <div className="preloader__dot" />
        </div>
        <div className="preloader__tag-content">
          <div className="preloader__tag">{textTag}</div>
        </div>
      </div>
    </div>
  )
}
