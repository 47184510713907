import React from 'react';
import './style.scss';

export default class Layout extends React.Component<{login?: boolean, href?: string}, {header}> {
    constructor(props) {
        super(props);
        this.state = {
            header: {
                logo: `${process.env.REACT_APP_URL}assets/images/logo.png`,
                alt: 'logo',
                button: `${process.env.REACT_APP_URL}assets/svg/login.svg`,
            }
        }
    }

    render() {
        return (
            <>
                {/*<Header {...this.state.header}/> текущей итерации нужно убрать */}
                <main>
                    {this.props.children}
                </main>
            </>
        )
    }
}
