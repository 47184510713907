import React from "react";
import './style.scss';
import cn from "classnames";

export type ButtonProps = {
  type?: string;
  text?: string;
  href?: string;
  question?: number;
  isOrange?: boolean;
  isArrow?: boolean;
  disabled?: boolean;
  onClick?: (state: any) => void
  className?: string
}

export class Button extends React.Component<ButtonProps, {}> {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick(val: any) {
    if (this.props.onClick) {
      this.props.onClick(val);
    }
  }

  render() {
    if (this.props.href) {
      return (
        <a
          className={`button ${this.props.isOrange ? 'button--orange' : ''} ${this.props.isArrow ? 'button--arrow' : ''}`}
          onClick={this.onClick} href={this.props.href}>
          {this.props.text}
        </a>
      );
    } else {
      return (
        <button
          className={
          cn(`button ${this.props.isOrange ? 'button--orange' : ''} ${this.props.isArrow ? 'button--arrow' : ''}`, this.props.className)
        }
          disabled={this.props.disabled} onClick={(e) => this.onClick(e)} style={this.props.question ? {
            background: `linear-gradient(to right, rgba(253, 158, 46, 0.6) 14.79%, rgba(253, 182, 45, 0.6) ${this.props.question}%, rgba(254, 212, 91, 0.6) ${this.props.question}%), linear-gradient(180deg, #FFE28D 0%, #FCC937 100%)`
          }
          : {}
        }>
          {this.props.text}
        </button>
      );
    }
  }
}
