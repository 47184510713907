import React from 'react'
import Layout from '../layouts/base';
import { Modal, ModalProps } from '../components/modal';
import { Aside } from '../components/aside';
import PerfectScrollbar from 'perfect-scrollbar';
import axios from 'axios';
import * as PIXI from 'pixi.js'

import '../styles/pages/test.scss';

import data1 from '../data/test_2.json';

const questionData = data1[process.env.REACT_APP_LANG || 'ru'];

const videoFileUrls = [
    "./assets/video/test2/01-seating-down.mp4",
    "./assets/video/test2/02-waiting.mp4",
    "./assets/video/test2/04-waiting.mp4",
    "./assets/video/test2/08-filler.mp4",
    "./assets/video/test2/05-disagree.mp4",
    "./assets/video/test2/03-agree.mp4",
    "./assets/video/test2/08-remembers.mp4",
];

type TestState = {
    isAuth: boolean,
    login: ModalProps,
    currentOption: {
        src: string,
        text: string,
        number: number,
        isLoop?: boolean
    },
	modal: ModalProps,
	staging: any,
	currentStage: string,
    question: string|number,
    pre_survey: ModalProps,
    data: object,
}

export default class Index extends React.Component<{}, TestState> {
    private testRef: React.RefObject<HTMLDivElement> | null;
    private app: PIXI.Application | null = null;
    private timer: any = null;
    private textures: PIXI.Texture[];

    constructor(props) {
        super(props);
        this.testRef = React.createRef();
        this.state = {
            isAuth: false,
            login: {
                title: process.env.REACT_APP_LANG === 'en' ? 'Welcome!' : 'Добро пожаловать! ',
                subtitle: process.env.REACT_APP_LANG === 'en' ? 'Enter your access code below.' : 'Для входа на портал введите код. ',
                form: {
                    action: "#",
                    method: "GET",
                    fields: [
                        {
                            type: 'text',
                            name: 'code',
                            placeholder: process.env.REACT_APP_LANG === 'en' ? 'Access code' : 'Код доступа'
                        },
                        {
                            type: 'button',
                            text: process.env.REACT_APP_LANG === 'en' ? 'Enter' : 'Войти'
                        }
                    ],
                    onSubmit: (e: any) => {
                        const authData = new FormData();
                        let errors:any = [];
                        e.preventDefault();

                        const formElems = [].slice.call(e.target.elements).filter((elem: any) => elem.tagName !== 'BUTTON');
                        formElems.forEach((item: any) => {
                            if (item.validity.valid && item.value) {
                                item.parentElement.removeAttribute('data-error');
                                authData.append(item.name, item.value);
                            } else {
                                item.parentElement.setAttribute('data-error', true);
                                errors.push(item.parentElement);
                            }
                        });


                        if (!errors.length) {
                            errors = [];
                            axios.post('/local/ajax/auth.php', authData).then((response) => {
                                if (response.data.status !== 'false') {
                                    this.setState({isAuth: true});
                                    this.createCanvas();
                                } else {
                                    const codeEl:any = formElems.filter((item: any) => item.name === 'code')[0];
                                    codeEl.parentElement.setAttribute('data-error', true);
                                    errors.push(codeEl);
                                }
                            }).catch(e => {
                                console.error(e);
                                this.setState({isAuth: true});
                                this.createCanvas();
                            });
                        } else {
                            const codeEl:any = formElems.filter((item: any) => item.name === 'code')[0];
                            codeEl.parentElement.setAttribute('data-error', true);
                            errors.push(codeEl);
                        }
                    }
                }
            },
            currentOption: {
                src: '',
                text: '',
                number: 0,
            },
            modal: {
                isMedium: true,
                title: process.env.REACT_APP_LANG === 'en' ? 'Hello, ladies and gentlemen!<br/>We invite you to take part in the interactive quest during which you will be able to carry out a full diagnosis of a patient of gastroenterological profile:' : 'Добрый день!<br/>Предлагаем принять участие в интерактивном квесте, в ходе которого Вы сможете провести полноценную диагностику пациента гастроэнтерологического профиля.',
                button: {
                    text: process.env.REACT_APP_LANG === 'en' ? 'Start' : 'Начать',
                    isArrow: true,
                    onClick: () => {this.setState((state) => {
                        return {currentStage: state.staging.START};
                    }, () => {
                        this.createReaction({
                            src: videoFileUrls[0],
                            text: '',
                            number: 0,
                        }, false);
                    })}
                }
            },
            pre_survey: {
                isSmall: true,
                icon: `${process.env.REACT_APP_URL}assets/svg/like.svg`,
                title: process.env.REACT_APP_LANG === 'en' ? 'Thank you for participation! You will see the results of the test during the webinar. Please, return to the webinar.' : 'Благодарим за участие! Результаты теста Вы увидите в ходе вебинара. Вернитесь на вебинар.'
            },
            staging: {
                PRE_START: "PRE_START",
                START: "START",
                PRE_SURVEY: "PRE_SURVEY",
            },
            currentStage: "PRE_START",
            question: 0,
            data: {},
        }
        this.textures = [];
    }

    loadVideo(src?, loop?) {
        if (this.app) {
            if (this.timer) {
                clearTimeout(this.timer);
            }
            const texture = this.textures.filter((item) => item.textureCacheIds[0] === src)[0];
            if (!texture) return;
            const resource = texture.baseTexture.resource as any;
            const videoSprite = new PIXI.Sprite(texture);
            videoSprite.width = this.app.screen.width;
            videoSprite.height = this.app.screen.height;
            while (this.app.stage.children.length > 2) {
                this.app.stage.removeChild(this.app.stage.children[0]);
            }
            this.app.stage.addChild(videoSprite);
            resource.source.play();
            resource.source.onended = () => {
                if (this.state.currentStage === this.state.staging.SURVEY || this.state.currentStage === this.state.staging.REDIRECT) {
                    return;
                }
                const srcPrev = resource.source.querySelector('source').src;
                this.timer = setTimeout(() => {
                    if (loop) {
                        resource.source.loop = true;
                        resource.source.play();
                        return;
                    } else if (srcPrev === src) {
                        resource.source.play();
                        return;
                    } else {
                        const num = this.randomInteger(1, 3);
                        this.loadVideo(videoFileUrls[num]);
                    }
                }, 2000);
            }
        }
	}

    createReaction(e, loop?) {
        if (!e) return;
        this.loadVideo(e.src, loop);
        this.setState({currentOption: e});
    }

    nextStep(val: string|number, e?: any, dataForm?: object) {
        if (val === 'reaction') {
            this.createReaction(e);
            return;
        }
        if (dataForm) {
            this.setState({data: Object.assign(this.state.data, dataForm)});
        }
        if (this.state.question === 9) {
            this.setState((state) => {
                return {currentStage: state.staging.PRE_SURVEY, question: 10};
            });
            this.sendResults();
        } else {
            this.setState((state) => {
                return {question: Number(val) || ((val === 'prev') ? 1 : Number(state.question) + 1)};
            },() => {
                const num = this.randomInteger(1, 3);
                this.setState({currentOption: {
                    src: videoFileUrls[num],
                    text: '',
                    number: this.state.currentOption.number,
                }});
            }
            );
        }
    }

    randomInteger(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    componentDidUpdate(_, state) {
        if (this.state.currentStage === this.state.staging.SURVEY && state !== this.state) {
            const survey = this.testRef?.current?.querySelector('.test__survey-list');
            if (!survey) return;
            new PerfectScrollbar(survey, {
                maxScrollbarLength: 80
            });
        }
    }

    sendResults() {
        const formData = new FormData();
        Object.keys(this.state.data).forEach((elem) => {
            if (elem === 'start' || elem === 'survey') return;
            formData.append(elem, Array.isArray(this.state.data[elem]) ? this.uniqResults(this.state.data[elem]) : this.state.data[elem]);
        });
        axios.post('/local/ajax/test2.php', formData).then((response) => {
            console.log(response);
        }).catch(e => {
            console.error(e);
        });
    }

    uniqResults(list) {
        return list.filter(function(item, pos) {
            return list.indexOf(item) === pos;
        });
    }

    createCanvas() {
        const canvas = document.querySelector('#canvas');
        (canvas as HTMLCanvasElement).width = window.innerWidth;
        (canvas as HTMLCanvasElement).height = window.innerWidth * 0.56;

        videoFileUrls.forEach((src) => {
            this.textures.push(PIXI.Texture.from(src))
        });

        this.app = new PIXI.Application({transparent: true});
        if (canvas) {
            canvas.appendChild(this.app.view);
        }
        this.setCanvasWH(this.app);
        this.app.resize();

        window.addEventListener('resize', () => {
            if (!this.app) return;
            this.setCanvasWH(this.app);
            this.app?.resize();
        });
    }

    setCanvasWH(app) {
        if (!app) return;
        if (window.innerWidth * 0.56 < window.innerHeight) {
            app.view.width = window.innerHeight / 0.56;
            app.view.height = window.innerHeight;
            app.screen.width = window.innerHeight / 0.56;
            app.screen.height = window.innerHeight;
        } else {
            app.view.width = window.innerWidth;
            app.view.height = window.innerWidth * 0.56;
            app.screen.width = window.innerWidth;
            app.screen.height = window.innerWidth * 0.56;
        }
    }

	render() {
		return (
            <Layout>
                { !this.state.isAuth ? <Modal {...this.state.login}/> :
                <div className='test test-man' ref={this.testRef}>
                    <>
                        <div id="canvas" style={this.state.currentStage === this.state.staging.PRE_SURVEY ? {visibility: 'hidden'}: {}}></div>
                    </>
                    { (this.state.currentStage !== this.state.staging.SURVEY && (this.state.currentOption.text || questionData[this.state.question]?.reaction)) &&
                        <div className={'test__reaction'} data-point={this.state.currentOption.number}>{this.state.currentOption.text || questionData[this.state.question]?.reaction}</div>
                    }
                    { this.state.currentStage === this.state.staging.PRE_START && <Modal {...this.state.modal}/> }
                    { this.state.currentStage === this.state.staging.START &&
                        <Aside {...questionData[this.state.question]} questions={questionData.length} question={this.state.question} onClickEvt={(val, e, data) => this.nextStep(val, e, data)}/>
                    }
                    { this.state.currentStage === this.state.staging.PRE_SURVEY && <Modal {...this.state.pre_survey}/> }
                </div> }
                <span className='id-test'>{process.env.REACT_APP_LANG === 'en' ? '2289842 (v1.0)' : 'RUS2289842 (v1.0)'}</span>
            </Layout>
		);
	}
}
